<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-22 15:00:06
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-31 14:52:58
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/components/addAccountModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="addAccountVisible"
    :title="(isAdd ? '添加' : '修改') + '账户'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="544px"
  >
    <div class="form-wrapper">
      <a-form-model
        ref="ruleForm"
        :model="query"
        :rules="rules"
        v-bind="layout"
        :colon="false"
      >
        <!-- 角色 -->
        <a-form-model-item
          :rules="[{ required: true, message: '请输入角色', trigger: 'change' }]"
          prop="role"
          label="角色"
        >
          <a-select placeholder="请选择账户角色" v-model="query.role" @change="changeRole">
            <a-select-option :key="item.id" :value="item.id" v-for="item in roleList">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 用户名 -->
        <a-form-model-item
          :rules="[{ required: true, message: '请输入用户名', trigger: 'blur' }]"
          prop="username"
          label="用户名"
        >
          <a-input v-model.trim="query.username" placeholder="请输入用户名" />
        </a-form-model-item>
        <!-- 邮箱 -->
        <a-form-model-item
          :rules="[
            {
              required: true,
              message: '请输入登录邮箱',
              trigger: 'blur'
            },
            {
              message: '登录邮箱必须为邮箱格式',
              trigger: 'blur',
              pattern: /^[A-Za-zd0-9_-]+([-_.][A-Za-zd0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
            }
          ]"
          prop="loginId"
          label="邮箱"
        >
          <a-input v-model.trim="query.loginId" placeholder="请输入邮箱" />
        </a-form-model-item>
        <!-- 密码 -->
        <a-form-model-item
          label="登录密码"
          prop="password"
          v-if="isAdd"
          :rules="[{ required: true, trigger: 'blur', validator: validatePass }]"
        >
          <a-input
            v-model.trim="query.password"
            ref="password"
            :type="isShow ? '' : 'password'"
            autocomplete="new-password"
            placeholder="请输入登录密码"
          >
            <a-icon style="cursor: pointer" @click="changeIsShow" slot="suffix" type="eye" />
          </a-input>
        </a-form-model-item>
        <!-- 联系人 -->
        <a-form-model-item prop="contact" label="联系人">
          <a-input v-model.trim="query.contact" placeholder="请输入联系人" />
        </a-form-model-item>
        <!-- 联系方式 -->
        <a-form-model-item prop="mobile" label="联系方式">
          <a-input v-model.trim="query.mobile" placeholder="请输入联系方式" />
        </a-form-model-item>
        <!-- 公司 -->
        <a-form-model-item prop="company" label="公司">
          <a-input v-model.trim="query.company" placeholder="请输入公司" />
        </a-form-model-item>
        <!-- 币种 -->
        <a-form-model-item label="币种" prop="cur" v-if="+currentRole.isAdmin === 1 && role === 'SUPER_ADMIN' && isAdd">
          <a-radio-group v-model="query.cur">
            <a-radio :value="0">人民币</a-radio>
            <a-radio :value="1">美元</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <!-- 供应商选择 -->
        <a-form-model-item label="供应商选择" prop="publisherType">
          <a-radio-group v-model="query.publisherType">
            <a-radio :value="1" v-if="+currentRole.isAdmin !== 2">全部</a-radio>
            <a-radio :value="0">指定供应商</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="关联供应商" prop="publisherIdList" v-if="+query.publisherType === 0">
          <m-select-more
            style="width: 100%"
            v-model="query.publisherIdList"
            :allData="supplierList"
            :searchById="true"
            :hasIcon="true"
            :showLabel="false"
            :showId="true"
            label="供应商"
          />
        </a-form-model-item>
        <a-form-model-item label="用户付费模式" prop="type" v-if="role === 'SUPER_ADMIN' && isAdmin">
          <a-radio-group v-model="query.type">
            <a-radio :value="1">预付费</a-radio>
            <a-radio :value="2">后付费</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <!-- 状态 -->
        <a-form-model-item label="状态" prop="sts">
          <a-switch :checked="query.sts === 'A'" size="small" @click="changeSts"></a-switch>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import getListMix from '@/mixins/getListForManage'
import { addSubAccount, updateSubAccount } from '@/apiForManage/system'
import { Encrypt } from '@/utils/jsencrypt'
import { getPublisherListByRole } from '@/apiForManage/common'
import { mapState } from 'vuex'
export default {
  name: 'AddSubAccount',
  mixins: [getListMix],
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 17 }
      },
      tempquery: {
        role: undefined,
        password: undefined,
        username: undefined,
        loginId: undefined,
        mobile: undefined,
        company: undefined,
        contact: undefined,
        publisherType: 1,
        publisherIdList: [],
        sts: 'A',
        cur: 0,
        type: 1
      },
      query: {},
      rules: {},
      isShow: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    accountInfo: {
      default: () => ({}),
      type: Object
    },
    roleList: {
      default: () => [],
      type: Array
    }
  },
  created () {
  },
  watch: {
    addAccountVisible: {
      async handler (val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.ruleForm.clearValidate()
          })
          if (this.isAdd) {
            await this.getPublisherListByRole()
            this.query = JSON.parse(JSON.stringify(this.tempquery))
          } else {
            await this.getPublisherListByRole()
            this.query = JSON.parse(JSON.stringify(this.accountInfo))
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    addAccountVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    currentRole () {
      const curId = this.query.role
      const role = this.roleList.find((role) => +curId === +role.id)
      if (!role) {
        return {}
      } else {
        return role
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (!this.query.role) {
        return false
      }
      return +this.roleList.find((item) => +item.id === +this.query.role).isAdmin === 1
    }
  },
  methods: {
    changeRole () {
      this.query.publisherIdList = []
      this.getPublisherListByRole()
      if (+this.currentRole.isAdmin === 2) {
        this.query.publisherType = 0
      }
    },
    // 获取角色列表
    async getPublisherListByRole () {
      // 媒体账号
      if (+this.currentRole.isAdmin === 2) {
        const { data = [] } = await getPublisherListByRole({
          role: this.query.role,
          id: this.query.id
        })
        this.supplierList = data
      } else {
        // 其他账号
        await this.getSupplierList()
      }
    },
    changeSts () {
      this.query.sts = this.query.sts === 'A' ? 'S' : 'A'
    },
    changeIsShow () {
      this.isShow = !this.isShow
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // 登录密码校验
    validatePass (rule, value, callback) {
      if (value === '' || value === undefined) {
        callback(new Error('请重新输入登录密码'))
      } else {
        if (value.length < 8) {
          callback(new Error('密码不低于8位'))
        } else {
          callback()
        }
      }
    },
    handleCancel () {
      this.addAccountVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const query = JSON.parse(JSON.stringify(this.query))
          let res
          if (this.isAdd) {
            query.password = Encrypt(query.password)
            res = await addSubAccount(query)
          } else {
            res = await updateSubAccount(query)
          }
          if (res.code === 200) {
            this.$message.success('添加成功')
            this.$emit('editSuccess')
            this.addAccountVisible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.form-wrapper{
  padding: 0 36px;
}
</style>
