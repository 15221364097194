/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-09 10:54:37
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-09 10:57:02
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/EditWarning/BaseForm/targetList.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const targetList = [
  {
    name: '供应商',
    id: 1
  },
  {
    name: '广告位',
    id: 2
  },
  {
    name: '广告源',
    id: 3
  },
  {
    name: '供应商 + 广告源',
    id: 4
  },
  {
    name: '广告位 + 广告源',
    id: 5
  }
]
