<template>
  <div class="cardBox">
    <a-card :bordered="false">
      <h1 class="titlePush">{{ this.names }}</h1>
      <template>
        <a-form-model ref="ruleForm" :model="query" v-bind="layout">
          <template v-if="this.isAdd !== 1">
            <span class="role">角色： {{ query.name }}</span>
            <p class="defaultRole">此角色默认角色，名称不可更改</p>
          </template>
          <template v-else>
            <a-form-model-item
              label="角色名称"
              prop="name"
              :rules="isAdd === 1 ? [{ required: true, message: '请输入角色名称', trigger: 'blur' }] : undefined"
            >
              <a-input v-model.trim="query.name" placeholder="请输入角色名称" :disabled="isAdd !== 1" />
            </a-form-model-item>
          </template>
          <a-form-model-item
            label="管理员角色"
            prop="isAdmin"
            v-if="role === 'SUPER_ADMIN'"
          >
            <a-switch :checked="+query.isAdmin === 1" @change="changeIsAdmin"/>
          </a-form-model-item>
          <a-form-model-item has-feedback label="角色权限" prop="menuOperateIds">
            <div class="xiaoMie">
              <div class="left-main-tags">
                <div class="left-main-tags-box" v-for="attr in dateStrings" :key="attr.id">
                  <div class="cust-tag-right-header">
                    <el-checkbox
                      style="width: 20%;"
                      :indeterminate="attr.datadeterminate"
                      v-model="attr.datacheckAll"
                      @change="handleCheckAlldata(attr)"
                    >
                      {{ attr.name }}
                    </el-checkbox>
                    <a-radio-group v-model="attr.radioData">
                      <a-radio
                        v-for="(item, inx) in attr.operateList"
                        :key="inx"
                        :disabled="item.disabled"
                        :value="item.id"
                      >
                        {{ item.operateName }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                  <div class="checkbox" v-if="attr.childMenu.length > 0">
                    <div class="checkbox_item" v-for="(attrs,idex) in attr.childMenu" :label="attrs" :key="attrs.id">
                      <el-checkbox
                        @change="handleCheckdata(attrs,idex)"
                        :disabled="attrs.disabled"
                        v-model="attrs.checked"
                      >
                        {{ attrs.name }}
                      </el-checkbox>
                      <a-radio-group v-model="attrs.radioData">
                        <a-radio
                          v-for="(item, inx) in attrs.operateList"
                          :key="inx"
                          :disabled="item.disabled"
                          :value="item.keyNum"
                          @click="handleCLick(item, attrs.radioData)"
                        >
                          {{ item.operateName }}
                        </a-radio>
                      </a-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-form-model-item>
        </a-form-model>
      </template>
      <div class="buttonPush">
        <a-button @click="$router.push({ path: '/accountInfo/rights' })">取消</a-button>
        <a-button type="primary" @click="handleSubmit">确定</a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
import {
  getMenuList,
  addRole,
  updateRole,
  getMenuAndOperateListByRoleId
} from '@/apiForManage/system'
import { mapState } from 'vuex'
export default {
  name: 'AddingRoleRights',
  data () {
    return {
      dateStrings: [],
      layout: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 14
        }
      },
      query: {},
      menuOperateIds: [],
      names: '',
      valsPush: [],
      showPushs: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    appId: {
      default: '',
      type: String
    },
    placeInfo: {
      default: () => ({}),
      type: Object
    },
    supplier: {
      type: Number,
      default: 0
    }
  },
  created () {
    const queryContent = this.$route.query || {}
    this.isAdd = Number(queryContent.isAdd)
    this.idPush = queryContent.keys
    this.names = this.isAdd === 1 ? '新增角色权限' : '修改角色权限'
    this.getMenuList()
  },
  watch: {
    query: {
      handler (val) {
        if (val) {
          this.showPushs = (val.name === '超级管理员' || val.name === '管理员' || val.name === '媒体') || false
        }
      },
      immediate: true
    },
    appId: {
      handler (val) {
        if (val) {
          this.query.appId = val
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      isAdmin: (state) => state.user.isAdmin,
      role: (state) => state.user.roles[0],
      resourceUrl: (state) => state.autoweb.resourceUrl,
      origin: (state) => state.autoweb.origin
    }),
    header () {
      return {
        Authorization: this.token
      }
    }
  },
  methods: {
    changeIsAdmin (e) {
      this.$set(this.query, 'isAdmin', e ? 1 : 0)
    },
    async getMenuAndOperateListByRoleId () {
      const role = {
        roleId: this.idPush
      }
      const res = await getMenuAndOperateListByRoleId(role)
      if (res.code === 200) {
        this.valsPush = res.data
        this.$set(this.query, 'name', this.valsPush[0].roleName)
        this.$set(this.query, 'isAdmin', this.valsPush[0].isAdmin)
        this.dateStrings.map((item) => {
          res.data.map((item3) => {
            if (item.childMenu.length === 0 && item3.menuId === item.id) {
              item.radioData = item.id
              item.datacheckAll = true
              item.operateList[0].disabled = false
            }
            item.childMenu && item.childMenu.map((item1) => {
              item1.operateList && item1.operateList.map((item2, key) => {
                if (item3.menuId === item2.menuId) {
                  item2.disabled = false
                  item1.checked = true
                  if (!item.childMenu.some((items) => !items.checked)) {
                    item.datadeterminate = false
                    item.datacheckAll = true
                  } else if (item.childMenu.some((item) => item.checked)) {
                    item.datadeterminate = true
                  }
                  item1.radioData = item3.operateName === '可编辑' ? item2.keyNum : item2.keyNum - key
                }
              })
            })
          })
        })
      }
    },
    handleCLick (data, radioData) {
      data.checked = radioData
    },
    // 点击二级菜单
    handleCheckdata (val) {
      val.operateList.map((item) => {
        item.disabled = !val.checked
        val.radioData = val.checked ? item.keyNum : 0
      })
      this.dateStrings.map((item) => {
        if (item.id === val.parentId) {
          if (item.childMenu.some((item) => item.checked)) {
            item.datadeterminate = true
          }
          if (!item.childMenu.some((item) => !item.checked)) {
            item.datadeterminate = false
            item.datacheckAll = true
          }
          if (!item.childMenu.some((item) => item.checked)) {
            item.datadeterminate = false
            item.datacheckAll = false
          }
        }
      })
    },
    // 点击级一菜单全选
    handleCheckAlldata (val) {
      if (val.childMenu.length === 0) {
        val.operateList.map((item) => {
          item.disabled = !val.datacheckAll
          val.radioData = val.datacheckAll ? item.id : -1
        })
      } else {
        const arr = []
        val.childMenu.map((item) => {
          if (item.disabled) return
          item.checked = val.datacheckAll
          item.operateList.length > 0 && item.operateList.map((item1) => {
            item1.disabled = !val.datacheckAll
            item.radioData = item.checked ? item1.keyNum : 0
          })
          arr.push(item)
        })
        val.datadeterminate = arr.length > 0 && arr.length < val.childMenu.length
      }
    },
    // 角色权限childMenus
    async getMenuList () {
      const res = await getMenuList()
      if (res.code === 200) {
        this.dateStrings = res.data?.map((item) => {
          item.datacheckAll = false
          item.datadeterminate = false
          // 没有childMenu时，手动添加操作权限数组
          if (item.childMenu.length === 0) {
            item.operateList = [
              { id: item.id, operateName: '可查看', disabled: true }
            ]
          }
          // 个人中心，只有超管登录可以看到sdk版本管理
          if (this.role !== 'SUPER_ADMIN' && item.id === 201) {
            item.childMenu = item.childMenu.filter((e) => {
              return e.id !== 61
            })
          }
          // https://app.mediatom.cn 当前域名下展示消息中心、工单管理
          if (item.id === 200 && this.origin !== 'https://app.mediatom.cn') {
            item.childMenu = item.childMenu.filter((e) => {
              return e.id !== 64 && e.id !== 65
            })
          }
          // 数据报表 => 不展示媒体报表
          if (item.id === 12) {
            item.childMenu = item.childMenu.filter((e) => {
              return e.id !== 17
            })
          }
          item.childMenu && item.childMenu.map((item1) => {
            item1.checked = false
            item1.radioData = 0
            // 员工和角色权限 不可选
            // if (item1.id === 31 || item1.id === 30) item1.disabled = true
            item1 && item1.operateList && item1.operateList.map((item2, key) => {
              item2.keyNum = item2.id + key
              item2.disabled = true
            })
          })
        })
        // 编辑时获取已选择的路由权限
        this.isAdd === 2 && this.getMenuAndOperateListByRoleId()
        // 处理 不展示代理商、广告主财务管理、首页
        this.dateStrings = res.data?.filter((item) => {
          return item.id !== 38 &&
                  item.id !== 42 &&
                  item.id !== 25 &&
                  item.id !== 33 &&
                  item.id !== 59 &&
                  item.id !== 28
        })
      }
    },
    // 提交
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.dateStrings.map((data) => {
            if (data.datacheckAll || data.datadeterminate) {
              if (data.operateList && data.operateList.length > 0) {
                this.menuOperateIds.push(
                  { menuId: data.operateList[0].id, operateId: [] }
                )
              } else {
                data.childMenu.map((item) => {
                  if (item.checked) {
                    item.operateList.map((item1) => {
                      if (item1.keyNum === item.radioData) {
                        this.menuOperateIds.push(
                          { menuId: item.id,
                            operateId: item1.operateName === '可编辑' ? [item1.id] : []
                          }
                        )
                      }
                    })
                  }
                })
              }
            }
          })
          const arr = []
          this.dateStrings.map((data1) => {
            if ((data1.id === 200 ||
                  data1.id === 201 ||
                  data1.id === 202 ||
                  data1.id === 203) &&
                (data1.datacheckAll || data1.datadeterminate)) {
              arr.push(data1.id)
            }
          })
          // 如果data1.id === 200 || 201 || 202 || 203 且选中，则需要加 个人中心菜单页，[{ menuId: 62, operateId: [] }
          this.menuOperateIds = arr.length > 0
            ? [...this.menuOperateIds, ...[{ menuId: 62, operateId: [] }]]
            : this.menuOperateIds
          if (this.menuOperateIds.length === 0) {
            this.$message.error('请选择角色权限')
          } else {
            const obj = {
              name: this.query.name,
              menuOperateIds: this.menuOperateIds,
              isAdmin: this.query.isAdmin || 0
            }
            const obj1 = {
              name: this.query.name,
              isAdmin: this.query.isAdmin,
              menuOperateIds: this.menuOperateIds,
              id: this.idPush
            }
            const list1 = {
              1: obj,
              2: obj1
            }
            const list = {
              1: addRole,
              2: updateRole
            }
            const resp = await list[this.isAdd](list1[this.isAdd])
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.$router.push({ path: '/accountInfo/rights' })
            }
            this.$emit('changePlace')
            this.addPlaceVisible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.cardBox {
  padding: 0 10px 10px 10px;
  border-radius: 5px;
  background: #f7f7f7;
  overflow-y: scroll;
  .buttonPush {
    text-align: right;
  }
  .buttonPush button {
    margin-top: 20px;
    margin-left: 10px;
  }
  .titlePush {
    margin-bottom: 20px;
    font-weight: 800;
  }
  .role {
    font-size: 12px;
  }
  .defaultRole {
    font-size: 12px;
    color: #999;
    margin-top: 10px;
  }
  .ant-card {
    margin: auto;
  }
  .left-main-tags {
    margin-bottom: 20px;
    border-radius: 5px;
    .left-main-tags-box{
      border: 1px solid #E6E6E6;
      margin-bottom: 20px;
    }
    .cust-tag-right-header {
      background: #FAFAFA;
      padding: 5px 16px;
    }
    .checkbox {
      padding: 0 16px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0;
      .checkbox_item {
        width: 50%;
        height: 40px;
        .el-checkbox {
          width: 40%;
        }
        .el-checkbox__inner {
          width: 16px;
          height: 16px;
        }
        input {
          font-size: 12px;
          cursor: pointer;
          width: 20px;
          height: 20px;
          vertical-align: middle;
          border-radius: 5px!important;
          margin-right: 10px;
        }
      }
    }
  }
  ::v-deep {
    .ant-card-body {
      background: #fff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      width: 60%;
      margin: auto;
      .ant-form-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10px;
        .ant-form-item-label {
          text-align: left;
          width: 100%;
        }
        .ant-form-item-control-wrapper {
          width: 100%;
        }
      }
    }
    input[type='radio'] {
      width: 20px;
      height: 20px;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #333333;
    }
    .el-checkbox__inner {
      border-radius: 3px;
    }
    .el-checkbox__inner {
      width: 18px;
      height: 18px;
    }
    .el-checkbox__inner::after {
      left: 5px;
      top: 2px;
      width: 5px;
      height: 9px;
    }
    .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
      top: 6px;
    }
  }
}
</style>
