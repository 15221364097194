/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-29 15:23:22
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-29 15:26:55
 * @FilePath: /mediatom-web/src/apiForManage/functionList/mapping.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const api = {
  getMappingPage: '/trafficPlace/page',
  updateMapping: '/trafficPlace/update',
  addMapping: '/trafficPlace/add',
  deleteMapping: '/trafficPlace/delete'
}

export default api
/**
 * 映射页查询
 * @export
 * @param {any} data
 * @returns
* */
export function getMappingPage (data) {
  return request({
    url: api.getMappingPage,
    method: 'post',
    data
  })
}
/**
 * 修改映射
 * @export
 * @param {any} data
 * @returns
* */
export function updateMapping (data) {
  return request({
    url: api.updateMapping,
    method: 'post',
    data
  })
}
/**
 * 添加映射
 * @export
 * @param {any} data
 * @returns
 */
export function addMapping (data) {
  return request({
    url: api.addMapping,
    method: 'post',
    data
  })
}

/**
 * 删除映射
 * @export
 * @param {any} data
 * @returns
* */
export function deleteMapping (data) {
  return request({
    url: api.deleteMapping,
    method: 'post',
    data
  })
}
