/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-06-18 15:25:59
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-06-18 15:27:25
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/FlowStrategy/list.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const strategyTypeList = [
  {
    name: '供应商 + 广告源',
    id: '1'
  },
  {
    name: '广告位 + 广告源',
    id: '2'
  },
  {
    name: '广告源',
    id: '3'
  }
]
