<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-26 18:55:47
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-29 19:21:01
 * @FilePath: /mediatom-web/src/components/QPS/QPSRatioForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="qps-ratio-container">
    <!-- QPS -->
    <div :id="`${propText}qpsLimit`">
      <!-- :rules="[{ validator: validatorQPS, trigger: 'blur' }, { validator: validatorRequestAndQps, trigger: 'blur' }, { required: isHour, message: '请输入QPS', trigger: 'blur' }]" -->
      <a-form-model-item
        :rules="[{ validator: validatorQPS, trigger: 'blur' }]"
        :prop="`${propText}qpsLimit`"
        label="QPS"
      >
        <a-input type="number" v-model.number.trim="formquery.qpsLimit" placeholder="请输入QPS"></a-input>
      </a-form-model-item>
    </div>
    <!-- 请求比例 -->
    <div :id="`${propText}requestRatio`">
      <!-- :rules="[{ validator: validatorRequestRatio, trigger: 'blur' }, { validator: validatorRequestAndQps, trigger: 'blur' }, { required: isHour, message: '请输入请求比例', trigger: 'blur' }]" -->
      <a-form-model-item
        :rules="[{ validator: validatorRequestRatio, trigger: 'blur' }]"
        :prop="`${propText}requestRatio`"
        label="请求比例"
      >
        <a-input type="number" v-model.number.trim="formquery.requestRatio" placeholder="请输入请求比例">
          <span class="percent-text" slot="suffix">%</span>
        </a-input>
      </a-form-model-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QPSRatioForm',
  created () {
    this.$set(this.formquery, 'qpsLimit', this.value.qpsLimit)
    this.$set(this.formquery, 'requestRatio', this.value.requestRatio)
  },
  computed: {
    formquery: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    isHour: {
      default: false,
      type: Boolean
    },
    value: {
      type: Object,
      default: () => ({})
    },
    propText: {
      default: '',
      type: String
    }
  },
  methods: {
    validatorQPS (rule, value, callback) {
      if (value < 0) {
        return callback(new Error('QPS必须大于等于0！'))
      } else {
        return callback()
      }
    },
    validatorRequestRatio (rule, value, callback) {
      if (value < 0) {
        return callback(new Error('请求比例必须大于等于0！'))
      } else if (value > 100) {
        return callback(new Error('请求比例必须小于等于100！'))
      } else {
        return callback()
      }
    },
    validatorRequestAndQps (rule, value, callback) {
      if (this.isHour) {
        return callback()
      } else {
        if (this.value.hourControlQps && (!this.formquery.qpsLimit && !this.formquery.requestRatio)) {
          return callback(new Error('请输入QPS或请求比例'))
        } else {
          return callback()
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.qps-ratio-container{
  color: red;
}
</style>
