<template>
  <div class="base-form-wrapper">
    <!-- 预警名称 -->
    <a-form-model-item
      prop="name"
      label="预警名称"
      :rules="[{ required: true, message: `请输入预警名称`, trigger: 'blur' }]"
    >
      <a-input style="width: 420px" placeholder="请输入预警名称" v-model.trim="formquery.name">
      </a-input>
    </a-form-model-item>
    <!-- 预警对象 -->
    <a-form-model-item
      prop="target"
      label="预警对象"
      :rules="[{ required: true, message: '请选择预警对象', trigger: 'change' }]"
    >
      <a-select :disabled="!isAdd" v-model="formquery.target" placeholder="请选择预警对象" style="width: 420px;" @change="changeTarget">
        <a-select-option v-for="item in targetList" :key="item.id">{{ item.name }}</a-select-option>
      </a-select>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { targetList } from './targetList'
export default {
  name: 'BaseForm',
  data () {
    return {
      // 表单默认值
      formqueryTemp: {
        name: undefined,
        target: 1
      },
      ismanager: process.env.VUE_APP_ISMANAGER === 'true',
      targetList
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...JSON.parse(JSON.stringify(val))
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        name: undefined,
        target: 1
      }),
      type: Object
    },
    isAdd: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    changeTarget () {
      this.$emit('changeTarget')
    },
    changeQuery () {
      this.$emit('changeQuery', this.formquery)
    }
  }
}
</script>

<style lang="less" scoped>
.base-form-wrapper {
  background: #fff;
  border-radius: 10px;
  ::v-deep {
    .has-error .ant-select-selection {
      border-color: var(--compBorderColor);
    }
    .has-error .ant-select-arrow {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
</style>
