<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-25 16:39:35
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-29 19:08:08
 * @FilePath: /mediatom-web/src/components/sourceModal/HourPricingStrategyFormForAdx/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="hour-pricing-strategy-container">
    <!-- 定时关闭 -->
    <a-form-model-item prop="timingOff" label="定时关闭">
      <a-radio-group v-model="formquery.timingOff">
        <a-radio :value="1"> 开启 </a-radio>
        <a-radio :value="0"> 关闭 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 关闭时间 -->
    <div id="timingOffTime">
      <a-form-model-item
        prop="timingOffTime"
        v-if="formquery.timingOff"
        label="关闭时间"
        :rules="[{ required: true, message: '请选择关闭时间', trigger: 'change' }]"
      >
        <el-date-picker
          :picker-options="{
            disabledDate: disabledDate
          }"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          v-model="formquery.timingOffTime"
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </a-form-model-item>
    </div>
    <!-- 分时段控制 -->
    <a-form-model-item prop="hourControlQps" label="分时段控制">
      <a-radio-group v-model="formquery.hourControlQps">
        <a-radio :value="1"> 开启 </a-radio>
        <a-radio :value="0"> 关闭 </a-radio>
      </a-radio-group>
      <a-button type="link" icon="plus" @click="handleAddHourItem" v-if="formquery.hourControlQps">增加时段</a-button>
    </a-form-model-item>
    <template v-if="formquery.hourControlQps">
      <div v-for="(item, index) in formquery.hourControlQpsList" :key="index">
        <a-form-model-item :label="`分时段控制${index + 1}`">
          <a-icon type="close" class="close-icon" @click="handleDeleteHourItem(index)" />
        </a-form-model-item>
        <div :id="`hourControlQpsList[${index}].hours`">
          <a-form-model-item
            label="选择时段"
            :prop="`hourControlQpsList[${index}].hours`"
            :rules="[{ required: true, validator: validatorHours, trigger: 'change' }]"
          >
            <el-time-picker
              :clearable="false"
              is-range
              value-format="HH:mm"
              format="HH:mm"
              @change="handleChangeHours($event, item)"
              v-model="item.hours"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
            >
            </el-time-picker>
          </a-form-model-item>
        </div>
        <QPSRatioForm isHour :propText="`hourControlQpsList[${index}].`" v-model="formquery.hourControlQpsList[index]" />
      </div>
    </template>
    <!-- 分界线 -->
    <div class="more-setting" v-if="formquery.hourControlQps">
      <a-form-model-item :wrapperCol="{ offset: 3, span: 17 }">
        <a-divider> 未配置时段 </a-divider>
      </a-form-model-item>
    </div>
  </div>
</template>

<script>
import DateMix from '@/mixins/initDate'
import QPSRatioForm from '@/components/QPS/QPSRatioForm'
export default {
  name: 'HourPricingStrategyFormForAdx',
  components: { QPSRatioForm },
  mixins: [DateMix],
  created () {
    this.$set(this.formquery, 'timingOff', this.value.timingOff || 0)
    this.$set(this.formquery, 'timingOffTime', this.value.timingOffTime)
    this.$set(this.formquery, 'hourControlQps', this.value.hourControlQps || 0)
    this.$set(this.formquery, 'hourControlQpsList', this.value.hourControlQpsList || [])
  },
  computed: {
    formquery: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    disabledDate (current) {
      return new Date().getTime() - 3600 * 24 * 1000 > current
    },
    initHours () {
      this.formquery.hourControlQpsList.forEach((item) => {
        const startList = item.startHour.split(':')
        const startH = parseInt(startList[0])
        const startM = parseInt(startList[1])
        const endList = item.endHour.split(':')
        const endH = parseInt(endList[0])
        const endM = parseInt(endList[1])
        this.$set(item, 'hours', [new Date(null, null, null, startH, startM), new Date(null, null, null, endH, endM)])
      })
    },
    handleChangeHours (e, item) {
      item.startHour = e[0]
      item.endHour = e[1]
    },
    handleAddHourItem () {
      if (!this.formquery.hourControlQpsList) {
        this.$set(this.formquery, 'hourControlQpsList', [])
      }
      this.formquery.hourControlQpsList.push({
        startHour: '00:00',
        endHour: '01:00',
        hours: [new Date(null, null, null, 0, 0), new Date(null, null, null, 1, 0)]
      })
    },
    handleDeleteHourItem (index) {
      this.formquery.hourControlQpsList.splice(index, 1)
    },
    validatorHours (rule, value, callback) {
      let flag = true
      this.formquery.hourControlQpsList.forEach((item, index) => {
        const startMin = this.moment(item.startHour, 'HH:mm').unix()
        const endMin = this.moment(item.endHour, 'HH:mm').unix()
        this.formquery.hourControlQpsList.forEach((item2, index2) => {
          const startMin2 = this.moment(item2.startHour, 'HH:mm').unix()
          const endMin2 = this.moment(item2.endHour, 'HH:mm').unix()
          if (index === index2) {
          } else if (startMin >= endMin2 || endMin <= startMin2) {
          } else {
            flag = false
          }
        })
      })
      if (!flag) {
        return callback(new Error('时段不能重叠'))
      }
      return callback()
    }
  }
}
</script>

<style lang="less" scoped></style>
