<template>
  <div class="edit-maskrule-container">
    <div class="inner">
      <GoBack @goBack="goBack" title1="高级功能" title2="屏蔽管理" :title3="`${isAdd ? '添加' : '修改'}屏蔽规则`"/>
      <a-form-model
        ref="ruleForm"
        class="ruleForm"
        :model="formquery"
        :rules="rules"
        v-bind="layout"
        :colon="false"
        labelAlign="left"
      >
        <a-spin class="edit-maskrule-spin" :spinning="isLoading">
          <BaseForm :isAdd="isAdd" :query="formquery" @changeQuery="changeQuery"/>
          <StrategyForm v-if="formquery.type !== 3" :sourceList="dspList" :query="formquery" @changeQuery="changeQuery"/>
          <RuleForm v-if="formquery.type !== 3" :query="formquery" @changeQuery="changeQuery"/>
          <FlowOrientForm
            v-if="formquery.type === 3"
            :countryList="countryList"
            :installAppList="installAppList"
            :brandList="brandList"
            :query="formquery"
            @changeQuery="changeQuery"/>
          <AdxFlowForm :crowdList="crowdList" v-if="+formquery.type === 3" v-model="formquery.shieldRuleVo"/>
          <GlobalAdxFlowForm v-if="+formquery.type === 3" v-model="formquery"/>
          <TimeWeekForm
            v-if="formquery.type === 3"
            :query="formquery"
            @changeQuery="changeQuery"/>
          <SupportPositionForm :positionList="positionList" v-if="+formquery.type === 3" v-model="formquery.shieldRuleVo"/>
          <div class="line"></div>
          <BindForm :supplierList="supplierList" :sourceList="dspList" :query="formquery" @changeQuery="changeQuery"/>
          <PlaceTreeFormForManager :checkedList="configuredList" :placeTree="placeTree" :query="formquery" @changePlaceListQuery="changePlaceListQuery" />
        </a-spin>
      </a-form-model>
      <div class="footer-btn">
        <div class="btn-box">
          <a-button class="btn" type="primary" @click="handleSubmit">{{ isAdd ? '添加' : '保存' }}</a-button>
          <a-button type="cust" v-if="isReplace && !isAdd" @click="handleCopyReplace">复制替换规则</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseForm from '@/components/CreativeForm/BaseForm'
import StrategyForm from '@/components/CreativeForm/StrategyForm'
import BindForm from '@/components/CreativeForm/BindForm'
import RuleForm from '@/components/CreativeForm/RuleForm'
import FlowOrientForm from '@/components/CreativeForm/FlowOrientForm'
import TimeWeekForm from '@/components/CreativeForm/FlowOrientForm/TimeWeekForm'
import PlaceTreeFormForManager from '@/components/CreativeForm/PlaceTreeFormForManager'
import { getPlaceInfo, addShieldRule, getShieldInfo, updateShieldRule, getDspSourceInfo } from '@/apiForManage/creativeMask'
import DspSoourceTreeForm from '@/components/CreativeForm/DspSoourceTreeForm'
import { getDspList, getBrandList, getDirectionalSettingAppList, getCountryList } from '@/apiForManage/common'
import GoBack from '@/components/common/GoBack'
import MixGetList from '@/mixins/getListForManage'
import AdxFlowForm from './AdxFlowForm'
import GlobalAdxFlowForm from './GlobalAdxFlowForm'
import SupportPositionForm from './SupportPositionForm'
export default {
  name: 'EditCreativeMask',
  components: { GoBack, BaseForm, StrategyForm, BindForm, RuleForm, PlaceTreeFormForManager, DspSoourceTreeForm, FlowOrientForm, AdxFlowForm, TimeWeekForm, SupportPositionForm, GlobalAdxFlowForm },
  mixins: [MixGetList],
  data () {
    return {
      isReplace: false,
      layout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 12 }
      },
      formquery: {},
      formquerytemp: {
        // basefrom
        name: undefined,
        type: undefined,
        // strategyform
        ratio: undefined,
        timeRules: [
        // 周一
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周二
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周三
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周四
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周五
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周六
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周日
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ]
      ],
        timeRuleType: 0,
        timeRuleSts: false,
        areaRules: [],
        areaRuleType: 0,
        areaRuleSts: false,
        bundleRules: [],
        bundleRuleType: 0,
        sourceRules: [],
        sourceRuleType: 0,
        equipmentRules: [],
        equipmentRuleType: 0,
        installRules: [],
        installRuleType: 0,
        countryRules: [],
        countryRuleType: 0,
        provinceRules: [],
        provinceRuleType: 0,
        flowTypeRuleType: 0,
        flowTypeRules: [],
        // ruleform
        packageRules: [
          // {
          //   bundle: undefined,
          //   keyword: undefined,
          //   creativeUrlList: [],
          //   uploadCreativeUrlList: [],
          //   isGeneral: 0
          // }
        ],
        placeIdList: [],
        dspIdList: [],
        publisherIdList: [],
        shieldRuleVo: {}
      },
      dspList: [],
      rules: {},
      isLoading: false,
      placeTree: [],
      configuredList: [],
      isAdd: undefined,
      shieldId: undefined,
      installAppList: [],
      countryList: [],
      brandList: []
    }
  },
  watch: {
    $route: {
      async handler (val) {
          this.isAdd = val.name === 'addmaskrule'
          const { query = {} } = val
          const { id } = query
          this.shieldId = id
          this.isLoading = true
          this.isReplace = false
          this.formquery = JSON.parse(JSON.stringify(this.formquerytemp))
          await this.getCountryList()
          await this.getBrandList()
          await this.getDirectionalSettingAppList()
          await this.getSupplierList()
          await this.getDspList()
          await this.getCrowdList()
          this.getPositionList()
          if (this.isAdd) {
            await this.getRuleInfo()
            await this.getPlaceInfo()
            this.formquery.dspIdList = []
          } else {
            await this.getRuleInfo()
            await this.getPlaceInfo(this.shieldId)
            await this.getDspSourceInfo(this.shieldId)
            this.isReplace = this.formquery.type === 1
          }
          this.isLoading = false
          this.$nextTick(() => {
            // this.$refs.ruleForm.$el.scrollTop = 0
            this.$refs.ruleForm.clearValidate()
          })
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    hasChina () {
      return this.formquery.countryRules.includes('CHN')
    }
  },
  created () {
  },
  methods: {
    async getCountryList () {
      const { data = [] } = await getCountryList()
      data.forEach((item) => {
        item.id = item.code
        item.name = item.nameCn
      })
      this.countryList = Object.freeze(data)
    },
    // 安装列表
    async getDirectionalSettingAppList () {
      const { data = [] } = await getDirectionalSettingAppList()
      this.installAppList = data
    },
    async getBrandList () {
      const { data = [] } = await getBrandList()
      data.forEach((item) => {
        item.id = '' + item.name
      })
      this.brandList = data
    },
    goBack () {
      this.$router.push({
        name: 'creativemask'
      })
    },
    async getDspList () {
      const { data = [] } = await getDspList({
        platAccountIdList: [],
        platIdList: []
      })
      this.dspList = data.map((item) => ({ searchId: item.platPlaceId, name: item.name, id: '' + item.id }))
    },
    getRuleForm (ruleList) {
      ruleList.forEach((rule) => {
        switch (+rule.ruleId) {
          case 6:
            this.$set(this.formquery, 'timeRuleSts', true)
            this.$set(this.formquery, 'timeRuleType', +rule.ruleType)
            const timeRules = rule.ruleWeekTimes
            this.$set(this.formquery, 'timeRules', timeRules)
            break
          case 4:
            this.$set(this.formquery, 'areaRuleSts', true)
            this.$set(this.formquery, 'areaRuleType', +rule.ruleType)
            const areaRules = rule.ruleContent.map((areaStr) => {
              areaStr = areaStr.split('-')
              return areaStr
            })
            this.$set(this.formquery, 'areaRules', areaRules)
            break
          case 17:
            this.$set(this.formquery, 'bundleRuleType', +rule.ruleType)
            const bundleRules = rule.ruleContent
            this.$set(this.formquery, 'bundleRules', bundleRules)
            break
          case 18:
            this.$set(this.formquery, 'sourceRuleType', +rule.ruleType)
            const sourceRules = rule.ruleContent
            this.$set(this.formquery, 'sourceRules', sourceRules)
            break
          case 7:
            this.$set(this.formquery, 'equipmentRuleType', +rule.ruleType)
            const equipmentRules = rule.ruleContent
            this.$set(this.formquery, 'equipmentRules', equipmentRules)
            break
          case 15:
            this.$set(this.formquery, 'installRuleType', +rule.ruleType)
            const installRules = rule.ruleContent
            this.$set(this.formquery, 'installRules', installRules)
            break
          case 16:
            this.$set(this.formquery, 'countryRuleType', +rule.ruleType)
            const countryRules = rule.ruleContent
            this.$set(this.formquery, 'countryRules', countryRules)
            break
          case 19:
            this.$set(this.formquery, 'flowTypeRuleType', +rule.ruleType)
            const flowTypeRules = rule.ruleContent
            this.$set(this.formquery, 'flowTypeRules', flowTypeRules)
            break
        }
      })
    },
    async getRuleInfo () {
      let resp
      if (this.isAdd && !this.shieldId) {
        resp = {}
        return
      }
      resp = await getShieldInfo({ id: this.shieldId })
      const { data = {} } = resp
      const { name, ratio, type, ruleList = [], creativeList = [], publisherIdList = [], shieldRuleVo = {} } = data
      if (!this.isAdd) {
        this.$set(this.formquery, 'name', name)
        this.$set(this.formquery, 'ratio', ratio)
        this.$set(this.formquery, 'publisherIdList', publisherIdList)
        this.$set(this.formquery, 'shieldRuleVo', shieldRuleVo)
        // 规则选择状态
        this.$set(this.formquery, 'timeRuleType', 0)
        this.$set(this.formquery, 'areaRuleType', 0)
        this.getRuleForm(ruleList)
      }
      this.$set(this.formquery, 'type', type)
      this.formquery.packageRules = creativeList
    },
    async getDspSourceInfo (id) {
      const { data = {} } = await getDspSourceInfo({
        id
      })
      const { dspIdList = [] } = data
      this.formquery.dspIdList = dspIdList.map((item) => '' + item)
    },
    async getPlaceInfo (id = undefined) {
      const { data = {} } = await getPlaceInfo({
        id
      })
      const { configuredList = [], items = [] } = data
      this.placeTree = items
      this.configuredList = configuredList
      this.formquery.placeIdList = configuredList.map((item) => item.placeId)
    },
    changePlaceListQuery (query, placeList) {
      this.formquery = JSON.parse(JSON.stringify(query))
      this.configuredList = placeList
    },
    changeQuery (query) {
      this.formquery = JSON.parse(JSON.stringify(query))
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) {
          return this.$message.warning('请将表单填写完整后提交！')
        }
        if (valid) {
          const query = this.getFetchQuery()
          if (this.isAdd) {
            const { code = 0 } = await addShieldRule(query)
            if (code === 200) {
              this.$message.success('新增屏蔽规则成功')
              this.goBack()
            }
          } else {
            query.id = this.shieldId
            const { code = 0 } = await updateShieldRule(query)
            if (code === 200) {
              this.$message.success('编辑屏蔽规则成功')
              this.goBack()
            }
          }
        } else {
          return false
        }
      })
    },
    getFetchQuery () {
      const {
        name,
        type,
        ratio,
        timeRules,
        timeRuleSts,
        timeRuleType,
        areaRules,
        areaRuleSts,
        areaRuleType,
        packageRules,
        placeIdList,
        dspIdList,
        bundleRules,
        bundleRuleType,
        sourceRules,
        sourceRuleType,
        equipmentRules,
        equipmentRuleType,
        installRules,
        installRuleType,
        countryRules,
        countryRuleType,
        flowTypeRules,
        flowTypeRuleType,
        publisherIdList,
        shieldRuleVo = {},
        shieldBudgetSwitch
      } = this.formquery
      // 规则
      const ruleList = []
      if (timeRuleSts && (type === 3 || type === 1)) {
        ruleList.push({
          ruleId: 6,
          ruleType: timeRuleType,
          ruleWeekTimes: timeRules
        })
      }
      if (areaRuleSts && this.formquery.type !== 3 || this.formquery.type === 3 && this.hasChina) {
         ruleList.push({
          ruleId: 4,
          ruleType: areaRuleType,
          ruleContent: areaRules.map((area) => area.join('-'))
        })
      }
      if (bundleRules.length) {
        ruleList.push({
          ruleId: 17,
          ruleType: bundleRuleType,
          ruleContent: bundleRules
        })
      }
      if (sourceRules.length) {
        ruleList.push({
          ruleId: 18,
          ruleType: sourceRuleType,
          ruleContent: sourceRules
        })
      }
      if (equipmentRules.length) {
        ruleList.push({
          ruleId: 7,
          ruleType: equipmentRuleType,
          ruleContent: equipmentRules
        })
      }
      if (installRules.length) {
        ruleList.push({
          ruleId: 15,
          ruleType: installRuleType,
          ruleContent: installRules
        })
      }
      if (countryRules.length) {
        ruleList.push({
          ruleId: 16,
          ruleType: countryRuleType,
          ruleContent: countryRules
        })
      }
      if (flowTypeRules.length) {
        ruleList.push({
          ruleId: 19,
          ruleType: flowTypeRuleType,
          ruleContent: flowTypeRules
        })
      }
      return {
        ...this.formquery,
        name,
        type,
        ratio,
        ruleList,
        placeIdList,
        dspIdList,
        publisherIdList,
        shieldRuleVo,
        shieldBudgetSwitch,
        creativeList: packageRules
      }
    },
    handleCopyReplace () {
      this.$router.push({
        name: 'addmaskrule',
        query: {
          id: this.shieldId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.edit-maskrule-container{
  position: relative;
  // min-width: 1280px;
  min-height: 500px;
  // height: calc(100vh - 60px);
  padding: calc(60px + @mediumMargin) @mediumMargin @mediumMargin @mediumMargin;
  .inner{
    border: 1px solid;
    height: 100%;
    background-color: #fff;
    border-radius: @mediumRadius;
    box-shadow: @modelShadow;
    border: 1px solid @modelBorderColor;
    position: relative;
  }
  .ruleForm {
    height: calc(100% - 70px);
    padding-left: 100px;
    padding-top: 40px;
    // padding-right: 520px;
    overflow-y: auto;
  }
  .footer-btn{
    height: 60px;
    border-top: 1px solid #eee;
    width: 100%;
    .btn-box{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 60px;
      button{
        width: 132px;
        margin-right: @compMargin;
      }
    }
  }

    overflow-y: auto;
    .ruleForm {
      ::v-deep {
        .ant-form-item {
          .ant-form-item-label {
            padding-left: 10px;
          }
          .ant-form-item-required::before {
            margin-right: 0;
            margin-left: -7px;
          }
        }
      }
    }
  }
  .line{
    border-bottom: 1px solid #e9e9e9;
    margin: 20px 0;
  }
</style>
