import request from '@/utils/request'

const api = {
  subAccountPager: 'user/userPager',
  addSubAccount: 'user/addUser',
  updateSubAccount: 'user/updateUser',
  deleteUser: '/user/deleteUser',
  getMenuList: 'role/getMenuList',
  getMenuAndOperateListByRoleId: 'role/getMenuAndOperateListByRoleId',
  addRole: 'role/addRole',
  updateRole: 'role/updateRole',
  getRoleListForRole: 'role/getRoleListForRole'
}

export default api
export function deleteUser (data) {
  return request({
    url: api.deleteUser,
    method: 'post',
    data
  })
}
export function subAccountPager (data) {
  return request({
    url: api.subAccountPager,
    method: 'post',
    data
  })
}
export function addSubAccount (data) {
  return request({
    url: api.addSubAccount,
    method: 'post',
    data
  })
}
export function updateSubAccount (data) {
  return request({
    url: api.updateSubAccount,
    method: 'post',
    data
  })
}
export function getMenuList (data) {
  return request({
    url: api.getMenuList,
    method: 'post',
    data
  })
}
export function getMenuAndOperateListByRoleId (data) {
  return request({
    url: api.getMenuAndOperateListByRoleId,
    method: 'post',
    data
  })
}
export function addRole (data) {
  return request({
    url: api.addRole,
    method: 'post',
    data
  })
}
export function updateRole (data) {
  return request({
    url: api.updateRole,
    method: 'post',
    data
  })
}
// 获取基本信息
export function getUserInfo (data) {
  return request({
    url: '/user/info',
    method: 'get',
    data
  })
}
// 修改信息
export function Info (data) {
  return request({
    url: '/user/Info',
    method: 'post',
    data
  })
}
// 获取验证码
export function verifyCode (data) {
  return request({
    url: `/user/verify/${data}`,
    method: 'post'
  })
}
// 修改密码
export function resetPassword (data) {
  return request({
    url: '/user/resetPassword',
    method: 'post',
    data
  })
}
export function getRoleListForRole (data) {
  return request({
    url: api.getRoleListForRole,
    method: 'post',
    data
  })
}
// 修改信息
export function updateAccountInfo (data) {
  return request({
    url: '/user/updateAccountInfo',
    method: 'post',
    data
  })
}
