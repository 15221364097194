<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-05 14:54:15
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-08-13 17:47:59
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/EditWarning/ContactForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="contact-form-wrapper">
    <!-- 通知方式 -->
    <a-form-model-item prop="noticTypes" label="通知方式">
      <a-checkbox-group v-model="formquery.noticTypes">
        <a-checkbox :value="1">邮箱</a-checkbox>
        <a-checkbox :value="2">短信</a-checkbox>
      </a-checkbox-group>
    </a-form-model-item>
    <a-form-model-item
      v-if="formquery.noticTypes?.includes(1)"
      :wrapperCol="{ offset: 3, span: 15 }"
      prop="emailList"
      :rules="[{ required: true, message: '请添加邮箱', trigger: 'change' }]"
    >
      <m-string-list
        style="width: 420px"
        :placeholder="`请输入邮箱，多个邮箱以英文逗号隔开`"
        v-model="formquery.emailList"
      />
    </a-form-model-item>
    <a-form-model-item
      v-if="formquery.noticTypes?.includes(2)"
      :wrapperCol="{ offset: 3, span: 15 }"
      prop="phoneList"
      :rules="[{ required: true, message: '请添加手机号', trigger: 'change' }]"
    >
      <m-string-list
        style="width: 420px"
        :placeholder="`请输入手机号，多个手机号以英文逗号隔开`"
        v-model="formquery.phoneList"
      />
    </a-form-model-item>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ContactForm',
  data () {
    return {
      // 表单默认值
      formqueryTemp: {
        noticTypes: [],
        phoneList: [],
        emailList: []
      },
      ismanager: process.env.VUE_APP_ISMANAGER === 'true'
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        // const formquery = {
        //   ...this.formqueryTemp,
        //   ...JSON.parse(JSON.stringify(val))
        // }
        // this.$emit('update:query', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        noticTypes: [],
        phoneList: [],
        emailList: []
      }),
      type: Object
    },
    isAdd: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    changeQuery () {
      this.$emit('changeQuery', this.formquery)
    }
  }
}
</script>

<style lang="less" scoped>
.contact-form-wrapper {
  background: #fff;
  border-radius: 10px;
  ::v-deep {
    .has-error .ant-select-selection {
      border-color: var(--compBorderColor);
    }
    .has-error .ant-select-arrow {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
</style>
