<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-28 17:50:40
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-30 14:02:18
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/sckz/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="sckz-container">
    <SckzSearch @handleSearch="handleSearch"/>
    <SckzTable ref="table"/>
  </div>
</template>

<script>
import SckzSearch from './SckzSearch'
import SckzTable from './SckzTable'
export default {
  components: { SckzSearch, SckzTable },
  methods: {
    handleSearch (query) {
      this.$nextTick(() => {
        this.$refs.table.dealQuery(query)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.sckz-container{
margin: 0 @mediumMargin @mediumMargin @mediumMargin;
}
</style>
