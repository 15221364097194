<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-03 10:22:59
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-05 17:50:39
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/Warning/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="warning-container">
    <div class="warning-head">
      <div class="search-box">
        <m-select
          v-model="searchquery.target"
          :showSearch="false"
          :dataList="targetList"
          label="预警对象"
          class="search-item"
          @change="handleSearch"
        />
        <m-select
          v-model="searchquery.createUser"
          :showSearch="false"
          :dataList="adminUserList"
          label="账户"
          class="search-item"
          @change="handleSearch"
          v-if="role === 'SUPER_ADMIN'"
        />
        <m-select
          v-model="searchquery.sts"
          :showSearch="false"
          :dataList="[
            { name: '开启', id: 'A' },
            { name: '关闭', id: 'S' }
          ]"
          label="状态"
          class="search-item"
          @change="handleSearch"
        />
        <a-input
          @change="handleInputName"
          placeholder="请输入预警名称"
          v-model.trim="searchquery.name"
          class="search-item input"
        ></a-input>
      </div>
      <a-button type="primary" class="btn" @click="$router.push({ name: 'addwarning'})">新增预警规则</a-button>
    </div>
    <div class="table-container">
      <a-spin :spinning="isLoading">
        <el-table :data="tableData" style="width: 100%">
          <!-- 预警名称 -->
          <el-table-column prop="name" label="预警名称" min-width="180">
          </el-table-column>
          <!-- 创建账户 -->
          <el-table-column prop="company" label="创建账户" v-if="role === 'SUPER_ADMIN'" min-width="180">
            <template slot-scope="{ row }">
              {{ row.company || row.username }}
            </template>
          </el-table-column>
          <!-- 最近预警时间 -->
          <el-table-column prop="noticeDate" label="最近预警时间" min-width="180">
            <template slot-scope="{ row }">
              {{ row.noticeDate || '--' }}
            </template>
          </el-table-column>
          <!-- 预警对象 -->
          <el-table-column prop="target" label="预警对象" min-width="100">
            <template slot-scope="{ row }">
              {{ +row.target === 1 ? '流量' : +row.target === 2 ? '预算' : '--' }}
            </template>
          </el-table-column>
          <!-- 预警频率 -->
          <el-table-column prop="frequency" label="预警频率" min-width="100">
            <template slot-scope="{ row }">
              {{ +row.frequency === 1 ? '天级' : +row.frequency === 2 ? '小时级' : '--' }}
            </template>
          </el-table-column>
          <!-- 预警方式 -->
          <el-table-column prop="noticeTypeStr" label="预警方式" min-width="100">
            <template slot-scope="{ row }">
              {{ row.noticeTypeStr || '--' }}
            </template>
          </el-table-column>
          <!-- 预警状态 -->
          <el-table-column prop="sts" label="预警状态" min-width="100">
            <template slot-scope="{ row }">
              <a-switch size="small" @click="handleChangeSts(row)" :checked="row.sts === 'A'"></a-switch>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column label="操作" min-width="200">
            <div slot-scope="{ row }" class="action">
              <span class="btn primary-text" @click="handleEdit(row)">编辑</span>
              <span class="btn primary-text" @click="handleDelete(row)">删除</span>
            </div>
          </el-table-column>
        </el-table>
        <m-empty v-if="tableData.length === 0" style="height: 341px" imgHeight="176px">
          <template #description> 暂时没有任何数据 </template>
        </m-empty>
        <a-pagination
          class="pagination"
          v-if="tableData.length > 0"
          show-size-changer
          :current="searchquery.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="searchquery.limit"
        />
      </a-spin>
    </div>
    <!-- 删除弹窗 -->
    <m-delete-modal
      :delVisible="delVisible"
      title="预警规则"
      @modalCancel="delVisible = false"
      @handleDelete="handleDeleteItem"
    />
  </div>
</template>

<script>
import getListMix from '@/mixins/getListForManage'
import { getWarningPage, updateWarningSts } from '@/apiForManage/functionList/warning'
import { mapState } from 'vuex'
import { targetList } from '../EditWarning/BaseForm/targetList'
export default {
  name: 'Warning',
  mixins: [getListMix],
  data () {
    return {
      delVisible: false,
      warningInfo: {},
      searchquery: {
        target: undefined,
        sts: undefined,
        name: undefined,
        createUser: undefined,
        page: 1,
        limit: 10
      },
      isLoading: false,
      tableData: [],
      total: 0,
      timer: null,
      targetList
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    })
  },
  created () {
    this.getAdminUserList()
    this.getDataList()
  },
  methods: {
    handleChangeSts (row) {
      this.changeWarningSts(row.id, row.sts === 'A' ? 'S' : 'A', '修改成功！')
    },
    async changeWarningSts (id, sts, msg) {
      const res = await updateWarningSts({
        id,
        sts
      })
        if (res.code === 200) {
          this.$message.success(msg)
          if (sts === 'D') {
            this.searchquery.page = 1
          }
          this.getDataList()
        }
    },
    handleInputName () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.handleSearch()
      }, 1000)
    },
    handleEdit (row) {
      this.$router.push({
        name: 'updatewarning',
        query: {
          id: row.id
        }
      })
    },
    handleDelete (row) {
      this.warningInfo = JSON.parse(JSON.stringify(row))
      this.delVisible = true
    },
    async handleDeleteItem () {
      await this.changeWarningSts(this.warningInfo.id, 'D', '删除成功！')
      this.delVisible = false
    },
    handleSearch () {
      this.searchquery.page = 1
      this.getDataList()
    },
    // 获取数据列表
    async getDataList () {
      this.isLoading = true
      const { data = {} } = await getWarningPage(this.searchquery)
      const { total = 0, items = [] } = data
      this.tableData = items
      this.total = total
      this.isLoading = false
    },
    handleChangePage (page) {
      this.searchquery.page = page
      this.getDataList()
    },
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.warning-container {
  padding: 0 @mediumMargin;
  .warning-head {
    display: flex;
    justify-content: space-between;
    .search-box {
      display: flex;
      .search-item {
        margin-right: @compMargin;
        width: 180px;
        &.input {
          width: 211px;
        }
      }
    }
    .btn {
      width: 144px;
    }
  }
  .table-container {
    margin-top: @mediumMargin;
    border-radius: @mediumRadius;
    background-color: #fff;
    padding: 0 @smallMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    .action {
      display: flex;
      .btn {
        cursor: pointer;
        &:first-child {
          margin-right: @compMargin;
        }
      }
    }
    .pagination {
      padding: @smallMargin 0;
    }
  }
}
</style>
