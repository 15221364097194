<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-08-23 10:23:10
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-08-23 10:24:47
 * @FilePath: /mediatom-web/src/components/CreativeForm/FlowOrientForm/TimeWeekForm.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="time-week-form-wrapper">
    <a-form-model-item label="时间策略">
      <a-switch size="small" v-model="formquery.timeRuleSts"></a-switch>
      <m-week-times v-if="formquery.timeRuleSts" v-model="formquery.timeRules"></m-week-times>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'FlowOrientForm',
  data () {
    return {
      // 表单默认值
      formqueryTemp: {
        timeRules: [],
        timeRuleSts: false
      }
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        timeRules: [],
        timeRuleSts: false
      }),
      type: Object
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
// .time-week-form-wrapper {
// }
</style>
