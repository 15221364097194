<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-06-26 14:27:45
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-09 16:21:12
 * @FilePath: /mediatom-web/src/components/CreativeForm/BindForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="bind-form-wrapper">
    <!-- 绑定包名 -->
    <a-form-model-item label="绑定包名" v-if="formquery.type !== 3">
      <m-string-list placeholder="多个包名以换行或中英文逗号分隔" v-model="formquery.bundleRules"/>
    </a-form-model-item>
    <!-- 绑定广告源 -->
    <a-form-model-item label="绑定广告源">
      <m-select-more
        style="width: 100%"
        v-model="formquery.sourceRules"
        :allData="sourceList"
        :searchById="true"
        :hasIcon="false"
        :showId="true"
        label="绑定广告源"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        :showLabel="false"
      />
    </a-form-model-item>
    <!-- 绑定供应商 -->
    <a-form-model-item label="绑定供应商">
      <m-select-more
        style="width: 100%"
        v-model="formquery.publisherIdList"
        :allData="supplierList"
        :searchById="true"
        :hasIcon="false"
        :showId="true"
        label="绑定供应商"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        :showLabel="false"
      />
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'BindForm',
  data () {
    return {
      // 表单默认值
      formqueryTemp: {
        bundleRules: [],
        bundleRuletype: 0,
        sourceRules: [],
        sourceRuleType: 0
      }
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        // 全为undefined
        bundleRules: [],
        bundleRuletype: 0,
        sourceRules: [],
        sourceRuleType: 0
      }),
      type: Object
    },
    sourceList: {
      default: () => [],
      type: Array
    },
    supplierList: {
      default: () => [],
      type: Array
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
// .bind-form-wrapper {
// }
</style>
