<template>
  <div class="personnel-container">
    <div class="ponsonnel-search">
      <div class="search-box">
        <!-- <a-select class="search-item" v-model="query.role" allowClear placeholder="请选择角色">
          <a-select-option v-for="item in roleList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select> -->
        <m-select v-model="query.role" label="角色" :dataList="roleList" class="search-item" @change="handleAutoSearch"/>
        <a-input class="search-item" allowClear placeholder="请输入用户名称或用户邮箱" v-model.trim="query.name" @change="handleDurationChange"></a-input>
      </div>
      <div class="btn">
        <a-button type="primary" class="add-btn" @click="handleClickAdd()">新增账户</a-button>
      </div>
    </div>
    <div class="table">
      <a-spin :spinning="loading">
        <el-table
          :data="dataList"
          style="width: 100%"
        >
          <!-- 创建时间 -->
          <el-table-column fixed="left" prop="registerTime" label="创建时间" min-width="180px">
          </el-table-column>
          <!-- 公司名称 -->
          <el-table-column fixed="left" prop="company" label="公司名称" min-width="220">
            <div style="width: 100%" slot-scope="{row}">
              {{ row.company || '--' }}
            </div>
          </el-table-column>
          <!-- 账户名称 -->
          <el-table-column fixed="left" prop="username" label="账户名称" min-width="200">
            <div style="width: 100%" slot-scope="{row}">
              {{ row.username }}
            </div>
          </el-table-column>
          <!-- 邮箱 -->
          <el-table-column prop="loginId" label="邮箱" min-width="200">
            <div style="width: 160px" class="ellipsis" slot-scope="{row}">
              {{ row.loginId }}
            </div>
          </el-table-column>
          <!-- 角色 -->
          <el-table-column prop="roleName" label="角色" min-width="200">
            <div style="width: 160px" class="ellipsis" slot-scope="{row}">
              {{ row.roleName || '--' }}
            </div>
          </el-table-column>
          <!-- 联系人 -->
          <el-table-column prop="contact" label="联系人" min-width="130">
            <div style="width: 110px" class="ellipsis" slot-scope="{row}">
              {{ row.contact || '--' }}
            </div>
          </el-table-column>
          <!-- 联系方式 -->
          <el-table-column prop="mobile" label="联系方式" min-width="130">
            <div style="width: 120px" class="ellipsis" slot-scope="{row}">
              {{ row.mobile || '--' }}
            </div>
          </el-table-column>
          <!-- 关联供应商数 -->
          <el-table-column label="关联供应商数" min-width="160">
            <div style="width: 150px" class="ellipsis about" slot-scope="{row}">
              <template v-if="+row.publisherType === 0">
                已关联供应商{{ row.publisherIdList.length }}个
              </template>
              <template v-else>已关联全部供应商</template>
            </div>
          </el-table-column>
          <!-- 状态 -->
          <el-table-column prop="sts" label="状态" min-width="100">
            <div :class="{sts: true, open: row.sts === 'A', close: row.sts === 'S'}" slot-scope="{row}">
              {{ row.sts === 'A' ? '开启' : '关闭' }}
            </div>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column label="操作" min-width="140" fixed="right">
            <div slot-scope="{row}" class="action-slot">
              <span class="btn" @click="handleEdit(row)">修改</span>
              <span class="btn" @click="clickDelete(row)">删除</span>
            </div>
          </el-table-column>
        </el-table>
      </a-spin>
      <m-empty v-if="dataList.length === 0" style="height: 341px" imgHeight="176px">
        <template #description> 暂时没有任何数据 </template>
      </m-empty>
      <a-pagination
        class="pagination"
        v-else
        show-size-changer
        :current="query.page"
        :show-total="(total) => `共 ${total} 条`"
        :total="total"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
      />
    </div>
    <AddAccountModal
      :isAdd="isAdd"
      :accountInfo="accountInfo"
      :visible="addAccountVisible"
      @modalCancel="addAccountVisible = false"
      :roleList="roleList"
      @editSuccess="getDataList"
    />

    <m-delete-modal
      :delVisible="delVisible"
      title="账户"
      @modalCancel="delVisible = false"
      @handleDelete="handleDelete"
    />
  </div>
</template>

<script>
import { subAccountPager, deleteUser, getRoleListForRole } from '@/apiForManage/system'
import { mapState } from 'vuex'
import AddAccountModal from '../components/EditAccountModal'
export default {
  name: 'Personnel',
  components: { AddAccountModal },
  data () {
    return {
      isAdd: false,
      loading: false,
      query: {
        page: 1,
        limit: 10,
        role: undefined,
        name: undefined
      },
      total: 0,
      roleList: [],
      dataList: [],
      accountInfo: {},
      addAccountVisible: false,
      delVisible: false,
      deleInfo: {},
      timer: null
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      mediaAccess: (state) => state.user.mediaAccess
    })
  },
  mounted () {
    this.getDataList()
    this.getRoleListForRole()
  },
  methods: {
    handleDurationChange () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.handleAutoSearch()
      }, 1000)
    },
    handleEdit (row) {
      this.isAdd = false
      this.accountInfo = JSON.parse(JSON.stringify(row))
      this.addAccountVisible = true
    },
    handleClickAdd () {
      this.isAdd = true
      this.addAccountVisible = true
    },
    // 获取报表数据
    async getDataList () {
      this.loading = true
      const { data = {}, code = 0 } = await subAccountPager(this.query)
      if (code === 200) {
        const { items = [], total = 0 } = data
        this.loading = false
        this.dataList = items
        this.total = total
      }
    },
    handleAutoSearch (value) {
      this.query.page = 1
      this.getDataList()
    },
    changePerson () {
      this.query.page = 1
      this.getDataList()
    },
    // 点击列表删除
    clickDelete (data) {
      this.delVisible = true
      this.deleInfo = data
    },
    // 删除
    handleDelete () {
      const record = this.deleInfo
      deleteUser({ id: record.id, sts: 'D' }).then((resp) => {
        if (resp.code === 200) {
          this.delVisible = false
          this.$message.success('删除成功')
          this.handleAutoSearch()
        }
      })
    },
    // 分页
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getDataList()
    },
    handleChangePage (page) {
      this.query.page = page
      this.getDataList()
    },
    // 获取角色列表
    getRoleListForRole () {
      getRoleListForRole().then((res) => {
        this.roleList = res.data || []
      })
    }
  }
}
</script>

<style lang="less" scoped>
.personnel-container {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  border-radius: 5px;
  .ponsonnel-search {
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .search-box{
      display: flex;
      justify-content: flex-start;
      .search-item{
        width: 200px;
        margin-right: @compMargin;
      }
    }
    .btn {
      display: flex;
      justify-content: flex-start;
      .add-btn{
        width: 116px;
      }
    }
  }
  .table{
    padding: 0 @smallMargin;
    background-color: #fff;
    border-radius: @mediumRadius;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    padding-top: calc(@smallMargin - @thPadding);
    .sts{
      border: 1px solid;
      width: 68px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      color: #fff;
      border-radius: @smallRadius;
      font-size: 12px;
      font-weight: 500;
      &.close{
        background-color: #5A607F;
      }
      &.open{
        background-color: @assisColor;
      }
    }
    .about{
      font-size: 14px;
      color: @primary-color;
    }
    .pagination{
      margin: @smallMargin 0;
    }
  }
  .ellipsis{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
      .action-slot{
      .btn{
        color: @primary-color;
        cursor: pointer;
      }
      .delete{
        color: red;
      }
      span{
        margin-right: 12px;
      }
    }
}
</style>
