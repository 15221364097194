<template>
  <div class="flow-orient-form-wrapper">
    <!-- 设备 -->
    <a-form-model-item label="设备">
      <div class="form-list-item">
        <a-select placeholder="请选择类型" v-model="formquery.equipmentRuleType" class="type">
          <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <m-select-more
          class="rule"
          v-model="formquery.equipmentRules"
          :allData="brandList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          label="设备品牌"
          :showLabel="false"
        />
      </div>
    </a-form-model-item>
    <!-- 安装列表 -->
    <a-form-model-item label="安装列表">
      <div class="form-list-item">
        <a-select placeholder="请选择类型" v-model="formquery.installRuleType" class="type">
          <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <m-select-more
          class="rule"
          v-model="formquery.installRules"
          :allData="installAppList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          label="安装列表"
          :showLabel="false"
        />
      </div>
    </a-form-model-item>
    <!-- 国家/地区 -->
    <a-form-model-item label="国家/地区">
      <div class="form-list-item">
        <a-select placeholder="请选择类型" v-model="formquery.countryRuleType" class="type">
          <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <m-select-more
          class="rule"
          v-model="formquery.countryRules"
          :allData="countryList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          label="国家/地区"
          :showLabel="false"
        />
      </div>
    </a-form-model-item>
    <!-- 省份 -->
    <a-form-model-item label="省份" v-if="hasChina">
      <div class="form-list-item">
        <a-select placeholder="请选择类型" v-model="formquery.areaRuleType" class="type">
          <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <el-cascader
          class="rule"
          v-model="formquery.areaRules"
          placeholder="请选择省份"
          :options="areaList"
          :props="{
            multiple: true
          }"
          collapse-tags
        />
      </div>
    </a-form-model-item>
    <!-- 包名 -->
    <a-form-model-item label="包名">
      <div class="form-list-item start-y">
        <a-select placeholder="请选择类型" v-model="formquery.bundleRuleType" class="type">
          <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <m-string-list class="rule" placeholder="多个包名以换行或中英文逗号分隔" v-model="formquery.bundleRules"/>
      </div>
    </a-form-model-item>
    <!-- 流量类型 -->
    <a-form-model-item label="流量类型">
      <a-checkbox-group v-model="formquery.flowTypeRules">
        <a-checkbox value="0">SDK</a-checkbox>
        <a-checkbox value="1">API</a-checkbox>
      </a-checkbox-group>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getAreaList } from '@/apiForManage/common'
export default {
  name: 'FlowOrientForm',
  data () {
    return {
      areaList: [],
      optionsStr2: [
        {
          value: 0,
          label: '包括'
        },
        {
          value: 1,
          label: '不包括'
        }
      ]
    }
  },
  created () {
    this.$set(this.formquery, 'equipmentRuleType', this.query.equipmentRuleType || 0)
    this.$set(this.formquery, 'equipmentRules', this.query.equipmentRules || [])
    this.$set(this.formquery, 'installRuleType', this.query.installRuleType || 0)
    this.$set(this.formquery, 'installRules', this.query.installRules || [])
    this.$set(this.formquery, 'countryRules', this.query.countryRules || [])
    this.$set(this.formquery, 'countryRuleType', this.query.countryRuleType || 0)
    this.$set(this.formquery, 'areaRuleType', this.query.areaRuleType || 0)
    this.$set(this.formquery, 'areaRules', this.query.areaRules || [])
    this.$set(this.formquery, 'flowTypeRules', this.query.flowTypeRules || [])
    this.getAreaList()
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        this.$emit('changeQuery', val)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    },
    hasChina () {
      return this.formquery.countryRules.includes('CHN')
    }
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    },
    brandList: {
      default: () => [],
      type: Array
    },
    installAppList: {
      default: () => [],
      type: Array
    },
    countryList: {
      default: () => [],
      type: Array
    }
  },
  methods: {
    // 获取地区列表
    async getAreaList () {
      const { data = [] } = await getAreaList()
      this.areaList = data
    }
  }
}
</script>

<style lang="less" scoped>
.flow-orient-form-wrapper {
  .form-list-item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    &.start-y{
      align-items: flex-start;
    }
    .type {
      width: 31%;
      margin-right: 20px;
    }
    .rule{
      flex: 1 auto;
    }
  }
}
</style>
