<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-29 10:52:16
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-29 17:41:04
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/Mapping/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="mapping-container">
    <MappingSearch @search="search" @handleAddMapping="handleAddMapping"/>
    <MappingTable ref="table"/>
  </div>
</template>

<script>
import MappingSearch from './MappingSearch'
import MappingTable from './MappingTable'
export default {
  components: { MappingSearch, MappingTable },
  methods: {
    // 搜索
    search (data) {
      this.$nextTick(() => {
        this.$refs.table.handleSearch(data)
      })
    },
    // 添加映射
    handleAddMapping () {
      this.$nextTick(() => {
        this.$refs.table.handleAddMapping()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.mapping-container{
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
}
</style>
