<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-08-22 09:52:48
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-26 16:48:40
 * @FilePath: /mediatom-web/src/components/CreativeForm/AdxFlowForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="adx-flow-form-container">
    <!-- 操作系统定向 -->
    <a-form-model-item label="操作系统定向" prop="shieldRuleVo.osFilterList">
      <a-checkbox-group v-model="shieldRuleVo.osFilterList">
        <a-checkbox value="1">安卓</a-checkbox>
        <a-checkbox value="0">IOS</a-checkbox>
      </a-checkbox-group>
    </a-form-model-item>
    <!-- 关键字过滤 -->
    <a-form-model-item label="关键字过滤">
      <div class="form-list-item start-y">
        <a-select placeholder="请选择类型" v-model="shieldRuleVo.keywordType" class="type">
          <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <m-string-list class="rule" placeholder="多个关键字以换行或中英文逗号分隔" v-model="shieldRuleVo.keywordFilterList"/>
      </div>
    </a-form-model-item>
    <!-- 预算类型定向 -->
    <a-form-model-item label="预算类型定向" prop="shieldRuleVo.acTypeFilterList">
      <a-checkbox-group v-model="shieldRuleVo.acTypeFilterList">
        <a-checkbox value="2">打开网页</a-checkbox>
        <a-checkbox value="3">deeplink</a-checkbox>
        <a-checkbox value="1">下载</a-checkbox>
        <a-checkbox value="7">微信程序</a-checkbox>
      </a-checkbox-group>
    </a-form-model-item>
    <!-- 亏损设置 -->
    <a-form-model-item prop="shieldRuleVo.lossSwitch" label="亏损设置">
      <a-switch size="small" :checked="shieldRuleVo.lossSwitch === 1" @click="handleChangeSwitch('lossSwitch')"></a-switch>
    </a-form-model-item>
    <template v-if="+shieldRuleVo.lossSwitch">
      <a-form-model-item
        prop="shieldRuleVo.lossRatio"
        label="亏损比例"
        :rules="[{ required: true, message: '请输入亏损比例', trigger: 'blur' }]"
      >
        <a-input type="number" :min="0" :max="100" v-model.number.trim="shieldRuleVo.lossRatio" placeholder="请输入亏损比例">
          <span class="percent-text" slot="suffix">%</span>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="亏损时间设置">
        <div class="time-box">
          <a-time-picker
            :allowClear="false"
            @change="
              (time, timeStr) => {
                cahngeTime(time, timeStr, 'lossStartHour')
              }
            "
            :default-value="moment(shieldRuleVo.lossStartHour || '00:00', 'HH:mm')"
            format="HH:mm"
          />
          <span style="margin-left: 10px"></span>
          <a-time-picker
            :allowClear="false"
            @change="
              (time, timeStr) => {
                cahngeTime(time, timeStr, 'lossEndHour')
              }
            "
            :default-value="moment(shieldRuleVo.lossEndHour || '00:00', 'HH:mm')"
            format="HH:mm"
          />
        </div>
      </a-form-model-item>
      <a-form-model-item label="重设时间设置" prop="shieldRuleVo.lossChongsheHour">
        <a-time-picker
          :allowClear="false"
          @change="
            (time, timeStr) => {
              cahngeTime(time, timeStr, 'lossChongsheHour')
            }
          "
          :default-value="moment(shieldRuleVo.lossChongsheHour || '00:00', 'HH:mm')"
          format="HH:mm"
        />
      </a-form-model-item>
    </template>
    <!-- UA过滤控制 -->
    <a-form-model-item prop="shieldRuleVo.uaValidFilterSwitch" label="UA过滤控制">
      <a-switch
        size="small"
        :checked="shieldRuleVo.uaValidFilterSwitch === 1"
        @click="handleChangeSwitch('uaValidFilterSwitch')"
      ></a-switch>
    </a-form-model-item>
    <a-form-model-item
      v-if="+shieldRuleVo.uaValidFilterSwitch === 1"
      prop="shieldRuleVo.uaValidRegular"
      label="UA字符串"
      :rules="[{ required: true, message: '请输入UA字符串', trigger: 'blur' }]"
    >
      <a-input v-model.trim="shieldRuleVo.uaValidRegular" placeholder="请输入UA字符串"> </a-input>
    </a-form-model-item>
    <!-- 人群包白名单 -->
    <a-form-model-item prop="shieldRuleVo.crowdSwitch" label="人群包白名单">
      <a-switch size="small" :checked="shieldRuleVo.crowdSwitch === 1" @click="handleChangeSwitch('crowdSwitch')"></a-switch>
    </a-form-model-item>
    <template v-if="+shieldRuleVo.crowdSwitch === 1">
      <a-form-model-item prop="shieldRuleVo.targetCrowdType" label="名单类型">
        <a-radio-group v-model="shieldRuleVo.targetCrowdType">
          <a-radio :value="1"> 黑名单 </a-radio>
          <a-radio :value="2"> 白名单 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item prop="shieldRuleVo.targetCrowds" label="名单">
        <m-select-more
          style="width: 100%"
          v-model="shieldRuleVo.targetCrowds"
          :allData="crowdList"
          :searchById="true"
          :hasIcon="false"
          :showId="false"
          label="人群包名单"
          :showSelectedPart="true"
          width="450px"
          :canSelectAll="true"
          :showLabel="false"
        />
      </a-form-model-item>
    </template>
    <!-- 日期定向 -->
    <a-form-model-item prop="shieldRuleVo.dateSwitch" label="日期定向">
      <a-switch size="small" :checked="shieldRuleVo.dateSwitch === 1" @click="handleChangeSwitch('dateSwitch')"></a-switch>
    </a-form-model-item>
    <a-form-model-item label="日期范围" v-if="+shieldRuleVo.dateSwitch === 1">
      <a-range-picker
        :allowClear="false"
        :default-value="[
          moment(shieldRuleVo.startDate || initDate(1, 0).end, dateFormat),
          moment(shieldRuleVo.endDate || initDate(1, 0).end, dateFormat)
        ]"
        :format="dateFormat"
        @change="changeDate"
      />
    </a-form-model-item>
  </div>
</template>

<script>
import moment from 'moment'
import mixDate from '@/mixins/initDate'
export default {
  name: 'AdxFlowForm',
  mixins: [mixDate],
  data () {
    return {
      dateFormat: 'YYYY-MM-DD',
      optionsStr2: [
        {
          value: 0,
          label: '包括'
        },
        {
          value: 1,
          label: '不包括'
        }
      ]
    }
  },
  created () {
    // 亏损设置
    this.$set(this.shieldRuleVo, 'lossSwitch', this.value.lossSwitch || 0)
    this.$set(this.shieldRuleVo, 'lossRatio', this.value.lossRatio || undefined)
    this.$set(this.shieldRuleVo, 'lossStartHour', this.value.lossStartHour || '00:00')
    this.$set(this.shieldRuleVo, 'lossEndHour', this.value.lossEndHour || '00:00')
    this.$set(this.shieldRuleVo, 'lossChongsheHour', this.value.lossChongsheHour || '00:00')
    // UA设置
    this.$set(this.shieldRuleVo, 'uaValidFilterSwitch', this.value.uaValidFilterSwitch || 0)
    this.$set(this.shieldRuleVo, 'uaValidRegular', this.value.uaValidRegular || undefined)
    // 日期定向
    this.$set(this.shieldRuleVo, 'dateSwitch', this.value.dateSwitch || 0)
    this.$set(this.shieldRuleVo, 'startDate', this.value.startDate || this.initDate(1, 0).end)
    this.$set(this.shieldRuleVo, 'endDate', this.value.endDate || this.initDate(1, 0).end)
    // 人群包白名单
    this.$set(this.shieldRuleVo, 'crowdSwitch', this.value.crowdSwitch || 0)
    this.$set(this.shieldRuleVo, 'targetCrowdType', this.value.targetCrowdType || 1)
    // 操作系统过滤
    this.$set(this.shieldRuleVo, 'osFilterList', this.value.osFilterList || [])
    // 关键字过滤
    this.$set(this.shieldRuleVo, 'keywordFilterList', this.value.keywordFilterList || [])
    this.$set(this.shieldRuleVo, 'keywordType', this.value.keywordType || 0)
    // 预算类型过滤
    this.$set(this.shieldRuleVo, 'acTypeFilterList', this.value.acTypeFilterList || [])
  },
  computed: {
    shieldRuleVo: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    },
    crowdList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    moment,
    handleChangeSwitch (key) {
      this.shieldRuleVo[key] = +!this.shieldRuleVo[key]
    },
    cahngeTime (time, timeStr, key) {
      this.shieldRuleVo[key] = timeStr
    },
    changeDate (date, dateStr) {
      this.shieldRuleVo.startDate = dateStr[0] || undefined
      this.shieldRuleVo.endDate = dateStr[1] || undefined
    }
  }
}
</script>

<style lang="less" scoped>
.form-list-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  &.start-y{
    align-items: flex-start;
  }
  .type {
    width: 31%;
    margin-right: 20px;
  }
  .rule{
    flex: 1 auto;
  }
}
</style>
