<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-29 14:58:19
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-08-07 14:27:31
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/Mapping/MappingSearch/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="search-wrapper">
    <div class="left">
      <m-select-more
        class="search-item"
        v-model="searchquery.publisherIdList"
        :allData="supplierList"
        :searchById="true"
        :hasSearch="true"
        :hasIcon="false"
        :showId="false"
        label="供应商"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        :showLabel="true"
        @change="changeSupplier"
      />
      <m-select-more
        class="search-item"
        v-model="searchquery.placeIdList"
        :allData="placeList"
        :searchById="true"
        :hasIcon="false"
        :showId="false"
        label="广告位"
        :showSelectedPart="true"
        :hasSearch="true"
        width="450px"
        :canSelectAll="true"
        :showLabel="true"
        @change="handleSearch"
      />
      <m-select-more
        class="search-item"
        v-model="searchquery.positionIdList"
        :allData="positionList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="广告样式"
        :showSelectedPart="true"
        :hasSearch="false"
        width="450px"
        :canSelectAll="true"
        :showLabel="true"
        @change="handleSearch"
      />
      <m-select
        @change="handleSearch"
        class="search-item"
        :showSearch="false"
        v-model="searchquery.sts"
        label="状态"
        :dataList="[
          { name: '开启', id: 'A' },
          { name: '关闭', id: 'S' }
        ]"
      />
    </div>
    <a-button style="width: 116px" type="primary" @click="$emit('handleAddMapping')">添加映射</a-button>
  </div>
</template>

<script>
import mixGetList from '@/mixins/getListForManage'
export default {
  name: 'MappingSearch',
  mixins: [mixGetList],
  data () {
    return {
      searchquery: {
        publisherIdList: [],
        placeIdList: [],
        positionIdList: [],
        sts: undefined
      }
    }
  },
  created () {
    this.getSupplierList()
    this.getPlaceList()
    this.getPositionList()
    this.handleSearch()
  },
  methods: {
    handleSearch () {
      this.$emit('search', this.searchquery)
    },
    async changeSupplier () {
      await this.getPlaceList(this.searchquery.publisherIdList)
      this.handleSearch()
    }
  }
}
</script>

<style lang="less" scoped>
.search-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-item {
    margin-right: @compMargin;
    width: 180px;
  }
}
</style>
