<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-29 15:33:24
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 11:26:35
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/Mapping/components/EditMapping.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="addMappingVisible"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="548px"
  >
    <!-- :title="(" -->
    <m-tips
      slot="title"
      :content="`将供应商的广告位ID与${title}广告位建立关联映射。<br/>当供应商请求时，我们会自动查找匹配的广告位ID，并应用相应的${title}广告位配置。<br/>如果找不到映射或ID不匹配，将使用供应商配置。`"
      :title="(isAdd ? '添加' : '修改') + '映射'"
      :width="200"
    ></m-tips>
    <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
      <!-- 映射名称 -->
      <a-form-model-item
        :rules="[{ required: true, message: '请输入映射名称', trigger: 'blur' }]"
        prop="name"
        label="映射名称"
      >
        <a-input v-model.trim="query.name" placeholder="请输入映射名称"></a-input>
      </a-form-model-item>
      <!-- 供应商 -->
      <a-form-model-item
        :rules="[{ required: true, message: '请选择供应商', trigger: 'change' }]"
        prop="publisherId"
        label="供应商"
      >
        <m-select
          label="供应商"
          @change="changeSupplier"
          style="width: 100%"
          :onlyLabel="false"
          :dataList="supplierList"
          v-model="query.publisherId"/>
      </a-form-model-item>
      <!-- 广告位 -->
      <a-form-model-item
        :rules="[{ required: true, message: '请选择广告位', trigger: 'change' }]"
        prop="placeId"
        label="广告位"
      >
        <m-select label="广告位" style="width: 100%" :onlyLabel="false" :dataList="placeList" v-model="query.placeId"/>
      </a-form-model-item>
      <!-- 映射数量 -->
      <a-form-model-item
        label="映射数量"
      >
        <a-input disabled :value="`${query.trafficPlaceIdList.length}条`"></a-input>
      </a-form-model-item>
      <!-- 映射ID -->
      <a-form-model-item
        label="映射ID"
        prop="trafficPlaceIdList"
      >
        <m-string-list filter placeholder="请输入广告位ID" v-model="query.trafficPlaceIdList"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import minGetList from '@/mixins/getListForManage'
import { mapState } from 'vuex'
import { addMapping, updateMapping } from '@/apiForManage/functionList/mapping'
export default {
  mixins: [minGetList],
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 17 }
      },
      query: {
        name: undefined,
        publisherId: undefined,
        placeId: undefined,
        trafficPlaceIdList: []
      },
      rules: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    mappingInfo: {
      default: () => ({}),
      type: Object
    }
  },
  created () {
  },
  watch: {
    visible: {
      async handler (val) {
        if (val) {
          await this.getSupplierList()
          if (this.isAdd) {
            this.query = {
              name: undefined,
              publisherId: undefined,
              placeId: undefined,
              trafficPlaceIdList: []
            }
            this.getPlaceList()
          } else {
            this.query = JSON.parse(JSON.stringify(this.mappingInfo))
            await this.getPlaceList([this.query.publisherId])
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title
    }),
    addMappingVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    // 供应商需改
    changeSupplier () {
      this.query.placeId = undefined
      this.getPlaceList([this.query.publisherId])
    },
    handleCancel () {
      this.addMappingVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            const resp = await addMapping(this.query)
            if (resp.code === 200) {
              this.$message.success('添加成功')
              this.$emit('editSuccess')
              this.addMappingVisible = false
            }
          } else {
            const resp = await updateMapping(this.query)
            if (resp.code === 200) {
              this.$message.success('修改成功')
              this.$emit('editSuccess')
              this.addMappingVisible = false
            }
          }
        } else {
          this.$message.error('请填写完整表单')
          return false
        }
      })
    }
  }
}
</script>

<style></style>
