<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-08-22 09:52:48
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-03 17:16:00
 * @FilePath: /mediatom-web/src/components/CreativeForm/AdxFlowForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="adx-flow-form-container">
    <a-form-model-item prop="shieldRuleVo.mediaTemplateIds" label="流量样式">
      <a-checkbox
        style="margin-right: 6px; margin-left: 0; margin-bottom: 14px"
        :checked="mediaTemplateIdsCheckedAll"
        :indeterminate="mediaTemplateIdsIndeterminate"
        @click="handleCheckedPositionAll"
      >全选</a-checkbox
      >
      <a-checkbox-group style="display: inline" v-model="formquery.mediaTemplateIds">
        <a-checkbox
          style="margin-right: 6px; margin-left: 0; margin-bottom: 14px"
          v-for="item in positionList"
          :key="item.id"
          :value="'' + item.id"
        >{{ item.name }}</a-checkbox
        >
      </a-checkbox-group>
    </a-form-model-item>
  </div>
</template>

<script>
import moment from 'moment'
import mixDate from '@/mixins/initDate'
export default {
  name: 'AdxFlowForm',
  mixins: [mixDate],
  data () {
    return {
    }
  },
  created () {
    this.$set(this.formquery, 'mediaTemplateIds', this.value.mediaTemplateIds || [])
  },
  computed: {
    formquery: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    mediaTemplateIdsIndeterminate () {
      return (
        this.formquery.mediaTemplateIds.length < this.positionList.length &&
        this.formquery.mediaTemplateIds.length > 0
      )
    },
    mediaTemplateIdsCheckedAll () {
      return this.formquery.mediaTemplateIds.length === this.positionList.length
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    },
    positionList: {
      default: () => ([]),
      type: Array
    }
  },
  methods: {
    moment,
    handleCheckedPositionAll () {
      if (this.mediaTemplateIdsCheckedAll) {
        this.formquery.mediaTemplateIds = []
      } else {
        this.formquery.mediaTemplateIds = this.positionList.map((item) => '' + item.id)
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
