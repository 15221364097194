<template>
  <div class="container-box">
    <div class="rights-container">
      <div class="rights_left">
        <a-button class="add-btn" type="primary" @click="handleClickAdd(1)">新增角色权限 </a-button>
        <a-menu
          :defaultSelectedKeys="this.default"
          :default-open-keys="['sub1']"
          :mode="mode"
          :theme="theme"
          type="inner"
          @click="handleClick"
        >
          <a-menu-item v-for="item in this.businessUser" :key="item.id">
            {{ item.name }}
          </a-menu-item>
        </a-menu>
      </div>
      <a-spin class="rights_right" :spinning="isLoading">
        <div class="editBtn" style="height: 40px">
          <a-icon type="user" class="usr-icon"/>
          <span style="font-size: 18px; font-weight: 600;margin-bottom: 15px">{{ titlePush }}</span>
          <a-button type="link" style="width: 80px" v-if="currentRole && +currentRole.isAdmin !== 2" @click="authorization">编辑</a-button>
        </div>
        <div class="left-main-tags-box" v-for="attr in treeData" :key="attr.id">
          <div class="cust-tag-right-header">
            <el-checkbox
              style="width: 20%"
              :indeterminate="attr.datadeterminate"
              v-model="attr.datacheckAll"
              :disabled="attr.disabled"
            >
              {{ attr.name }}
            </el-checkbox>
            <a-radio-group v-model="attr.radioData">
              <a-radio v-for="(item, inx) in attr.operateList" :key="inx" :disabled="item.disabled" :value="item.id">
                {{ item.operateName }}
              </a-radio>
            </a-radio-group>
          </div>
          <div class="checkbox" v-if="attr.childMenu.length > 0">
            <div class="checkbox_item" v-for="attrs in attr.childMenu" :label="attrs" :key="attrs.id">
              <el-checkbox :disabled="attrs.disabled" v-model="attrs.checked">{{ attrs.name }}</el-checkbox>
              <a-radio-group v-model="attrs.radioData">
                <a-radio
                  v-for="(item, inx) in attrs.operateList"
                  :key="inx"
                  :disabled="item.disabled"
                  :value="item.keyNum"
                >
                  {{ item.operateName }}
                </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
      </a-spin>

      <AddingRoleRights
        v-if="addPlaceVisible"
        :placeInfo="placeInfo"
        :visible="addPlaceVisible"
        @modalCancel="addPlaceVisible = false"
        :isAdd="this.advertisers"
        @changePlace="changePlace"
      />
    </div>
  </div>
</template>

<script>
import {
  getMenuList, // 列表
  getMenuAndOperateListByRoleId,
  getRoleListForRole
} from '@/apiForManage/system'
import AddingRoleRights from '../components/addingrolerights'
import { mapState } from 'vuex'
export default {
  name: 'Rights',
  components: {
    AddingRoleRights
  },
  data () {
    return {
      advertisers: '',
      addPlaceVisible: false,
      placeInfo: {},
      isLoading: false,
      groupList: [],
      mode: 'inline',
      theme: 'light',
      pid: [],
      query: {
        page: 1,
        limit: 10
      },
      businessUser: [],
      dateStrings: [],
      keys: 0,
      keyse: '',
      default: [],
      namePush: '',
      titlePush: '',
      treeData: []
      // ifEdit: false
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      origin: (state) => state.autoweb.origin
    }),
    currentRole () {
      return this.businessUser.find((item) => item.id === this.keys)
    }
  },
  mounted () {
    this.getRoleListForRole()
  },
  watch: {
    handler (val) {
      if (val.tradePid && val.tradeId) {
        this.pid = [val.tradePid, val.tradeId]
        this.query = JSON.parse(JSON.stringify(val))
      }
    }
  },
  methods: {
    // 新增角色权限
    handleClickAdd () {
      this.$router.push({
        path: `/accountInfo/AddingRoleRights?isAdd=${1}`
      })
    },
    // 编辑角色权限
    authorization () {
      const t = {}
      t.s = this.dateStrings
      this.placeInfo = t
      this.$router.push({
        path:
          `/accountInfo/AddingRoleRights?keys=${this.keys}&isAdd=${2}&t=` + encodeURI(JSON.stringify(this.placeInfo))
      })
    },
    // 点击左侧菜单栏
    handleClick (e) {
      this.keys = e.key
      // let currentUser
      this.businessUser.forEach((item) => {
        if (this.keys === item.id) {
          // currentUser = item
          this.titlePush = item.name
        }
      })
      // this.ifEdit = (this.titlePush === '管理员' || this.titlePush === '媒体' || this.titlePush === '超级管理员') || +currentUser.modifiable !== 1
      this.getMenuList()
    },
    // 获取所以路由菜单
    async getMenuList () {
      this.isLoading = true
      const res = await getMenuList()
      if (res.code === 200) {
        this.treeData = res.data?.map((item) => {
          item.disabled = true
          item.datacheckAll = false
          item.datadeterminate = false
          // 没有childMenu时，手动添加操作权限数组
          if (item.childMenu.length === 0) {
            item.operateList = [{ id: item.id, operateName: '可查看', disabled: true }]
          }
          // 个人中心，只有超管登录可以看到sdk版本管理
          if (this.role !== 'SUPER_ADMIN' && item.id === 201) {
            item.childMenu = item.childMenu.filter((e) => {
              return e.id !== 61
            })
          }
          // https://app.mediatom.cn 当前域名下展示消息中心、工单管理
          if (item.id === 200 && this.origin !== 'https://app.mediatom.cn') {
            item.childMenu = item.childMenu.filter((e) => {
              return e.id !== 32 && e.id !== 63
            })
          }
          // 数据报表 => 不展示媒体报表
          if (item.id === 12 && this.titlePush !== '媒体') {
            item.childMenu = item.childMenu.filter((e) => {
              return e.id !== 17
            })
          }
          item.childMenu &&
            item.childMenu.map((item1) => {
              item1.checked = false
              item1.radioData = 0
              item1.disabled = true
              item1 &&
                item1.operateList &&
                item1.operateList.map((item2, key) => {
                  item2.keyNum = item2.id + key
                  item2.disabled = true
                })
            })
        })
        // 处理 不展示代理商、广告主财务管理、首页
        this.treeData = res.data.filter((item) => {
          return (
            item.id !== 38 && item.id !== 42 && item.id !== 25 && item.id !== 33 && item.id !== 59 && item.id !== 28
          )
        })
        this.getMenuAndOperateListByRoleId()
      }
    },

    // 获取左侧已有菜单
    async getRoleListForRole () {
      const res = await getRoleListForRole()
      this.titlePush = ''
      if (res.code === 200) {
        this.businessUser = res.data
        this.default.push(res.data[0].id)
        this.keyse = res.data[0].id
        this.keys = res.data[0].id
        this.titlePush = res.data[0].name
        // this.ifEdit =
        //   (this.titlePush === '管理员' || this.titlePush === '媒体' || this.titlePush === '超级管理员') || (+res.data[0].modifiable !== 1)
        this.businessUser.forEach((item) => {
          if (item.id === this.keyse * 1) {
            this.namePush = item.name
          }
        })
      }
      this.getMenuList()
    },
    // 根据角色获取当前路由菜单
    async getMenuAndOperateListByRoleId () {
      const role = {
        roleId: this.keys
      }
      const res = await getMenuAndOperateListByRoleId(role)
      if (res.code === 200) {
        this.treeData.map((item) => {
          res.data.map((item3) => {
            if (item.childMenu.length === 0 && item3.menuId === item.id) {
              item.radioData = item.id
              item.datacheckAll = true
            }
            item.childMenu &&
              item.childMenu.map((item1) => {
                item1.operateList &&
                  item1.operateList.map((item2, key) => {
                    item2.disabled = true
                    if (item3.menuId === item2.menuId) {
                      item1.checked = true
                      if (!item.childMenu.some((items) => !items.checked)) {
                        item.datadeterminate = false
                        item.datacheckAll = true
                      } else if (item.childMenu.some((item) => item.checked)) {
                        item.datadeterminate = true
                      }
                      item1.radioData = item3.operateName === '可编辑' ? item2.keyNum : item2.keyNum - key
                    }
                  })
              })
          })
        })
        this.isLoading = false
      }
    },
    // 新增或编辑后 刷新页面
    changePlace () {
      this.keys = this.keys
      this.getRoleListForRole()
      this.timer = setTimeout(() => {
        this.getMenuList()
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.container-box {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  .rights-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;

    .rights_left, .rights_right{
      padding: @smallMargin;
      border-radius: @mediumRadius;
      border: 1px solid @modelBorderColor;
      box-shadow: @modelShadow;
    }
    .rights_left {
      background: #fff;
      display: flex;
      flex-direction: column;
      min-width: 192px;
      overflow-y: auto;
      position: relative;
      margin-right: @mediumMargin;
      .add-btn{
        width: 100%;
      }
    }
    .rights_right {
      overflow-y: auto;
      background-color: #fff;
      flex: auto;
      .usr-icon{
        font-size: 14px;
        margin-right: 10px;
        border: 1px solid;
        color: #999;
        border-radius: 50%;
        overflow: hidden;
      }
      .editBtn span{
        color: @defaultFontColor;
      }
    }
    .ant-menu-item-selected {
      background-color: #fff;
      color: @primary-color;
      &::after {
        opacity: 0;
      }
      &::before{
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: @primary-color;
        border-radius: 50%;
        right: 3px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .left-main-tags-box {
      border: 1px solid #e6e6e6;
      margin-bottom: 20px;
    }
    .cust-tag-right-header {
      background: #fafafa;
      padding: 10px 16px;
    }
    .checkbox {
      padding: 0 16px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0;
      .checkbox_item {
        width: 50%;
        height: 40px;
        .el-checkbox {
          width: 40%;
        }
        .el-checkbox__inner {
          width: 16px;
          height: 16px;
        }
        input {
          font-size: 12px;
          cursor: pointer;
          width: 20px;
          height: 20px;
          vertical-align: middle;
          border-radius: 5px !important;
          margin-right: 10px;
        }
      }
    }
    .rights_date {
      margin-left: 20%;
      margin-right: 10px;
    }

    .rights_industry {
      margin-left: 10px;
      margin-right: 10px;
    }

    .rights_businessUser {
      margin-right: 10px;
    }
    ::v-deep {
      .ant-menu-inline{
        border-right: none;
      }
      .ant-menu-item{
        padding-left: 5px!important;
        padding-right: 0px!important;
      }
      .el-checkbox__inner {
        border-radius: 3px;
      }
      input[type='radio'] {
        width: 20px;
        height: 20px;
      }
      .el-checkbox__inner {
        width: 18px;
        height: 18px;
      }
      .el-checkbox__inner::after {
        left: 5px;
        top: 2px;
        width: 5px;
        height: 9px;
      }
      .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        border-color: #fff;
      }
      .el-checkbox__input.is-disabled.is-indeterminate .el-checkbox__inner {
        color: #fff;
      }
      .el-checkbox__input.is-disabled.is-indeterminate .el-checkbox__inner::before {
        color: #fff;
        top: 6px;
      }
    }
  }
}
</style>
