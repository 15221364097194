/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-06-18 17:24:56
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-06-18 18:24:15
 * @FilePath: /mediatom-web/src/apiForManage/functionList/flowStrategy.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const api = {
  getFlowStrategyPage: '/trafficStrategy/pager',
  addFlowStrategy: '/trafficStrategy/add',
  updateFlowStrategy: '/trafficStrategy/update',
  deleteFlowStrategy: '/trafficStrategy/delete'
}

export default api

export function getFlowStrategyPage (data) {
  return request({
    url: api.getFlowStrategyPage,
    method: 'post',
    data
  })
}

export function updateFlowStrategy (data) {
  return request({
    url: api.updateFlowStrategy,
    method: 'post',
    data
  })
}
export function addFlowStrategy (data) {
  return request({
    url: api.addFlowStrategy,
    method: 'post',
    data
  })
}
export function deleteFlowStrategy (data) {
  return request({
    url: api.deleteFlowStrategy,
    method: 'post',
    data
  })
}
