<template>
  <div class="period-form-wrapper">
    <!-- 预警频率 -->
    <a-form-model-item
      prop="frequency"
      label="预警频率"
    >
      <a-radio-group v-model="formquery.frequency">
        <a-radio :value="1"> 天级 </a-radio>
        <a-radio :value="2"> 小时级 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 触发周期 -->
    <a-form-model-item
      prop="triggerDayList"
      label="触发周期"
      :rules="[{ required: true, message: '请选择触发周期', trigger: 'change' }]"
    >
      <a-checkbox-group v-model="formquery.triggerDayList">
        <a-checkbox :value="item.id" v-for="item in triggerDayList" :key="item.id">{{ item.name }}</a-checkbox>
      </a-checkbox-group>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'PeriodForm',
  data () {
    return {
      // 表单默认值
      formqueryTemp: {
        frequency: 1,
        triggerDayList: []
      },
      ismanager: process.env.VUE_APP_ISMANAGER === 'true',
      triggerDayList: [
        {
          name: '周一',
          id: '1'
        },
        {
          name: '周二',
          id: '2'

        },
        {
          name: '周三',
          id: '3'
        },
        {
          name: '周四',
          id: '4'
        },
        {
          name: '周五',
          id: '5'
        },
        {
          name: '周六',
          id: '6'
        },
        {
          name: '周日',
          id: '7'
        }
      ]
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...JSON.parse(JSON.stringify(val))
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        frequency: 1,
        triggerDayList: []
      }),
      type: Object
    },
    isAdd: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    changeQuery () {
      this.$emit('changeQuery', this.formquery)
    }
  }
}
</script>

<style lang="less" scoped>
.period-form-wrapper {
  background: #fff;
  border-radius: 10px;
  ::v-deep {
    .has-error .ant-select-selection {
      border-color: var(--compBorderColor);
    }
    .has-error .ant-select-arrow {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
</style>
