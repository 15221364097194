/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-29 15:23:22
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-05 18:02:56
 * @FilePath: /mediatom-web/src/apiForManage/functionList/mapping.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const api = {
  addWarning: '/earlyWarning/addWarning',
  getWarningPage: '/earlyWarning/getWarningPage',
  updateWarningSts: '/earlyWarning/updateWarningSts',
  getWarningInfo: '/earlyWarning/getWarningInfo',
  updateWarning: '/earlyWarning/updateWarning'
}

export default api
export function addWarning (data) {
  return request({
    url: api.addWarning,
    method: 'post',
    data
  })
}

export function getWarningPage (data) {
  return request({
    url: api.getWarningPage,
    method: 'post',
    data
  })
}

export function updateWarningSts (data) {
  return request({
    url: api.updateWarningSts,
    method: 'post',
    data
  })
}

export function getWarningInfo (data) {
  return request({
    url: api.getWarningInfo,
    method: 'post',
    data
  })
}
export function updateWarning (data) {
  return request({
    url: api.updateWarning,
    method: 'post',
    data
  })
}
